import React from "react"
import { connect } from "react-redux"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import min from "date-fns/min"
import differenceInBusinessDays from "date-fns/differenceInBusinessDays"
import {
  Heading,
  Text,
  Flex,
  Box,
  Tooltip,
  Checkbox,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react"
import { LabelTop } from "../../componentsWithChakra"
import TrafficLight from "../TrafficLight"
import GanttChart from "../GanttChart"
import GanttChartResize from "../GanttChartResize"
import colorCodeCalc from "../../functions/eval/colorCodeCalc"
import parseInt0 from "../../functions/utils/parseInt0"
import {
  selectedPersonsToString,
  selectedToString,
} from "../../functions/helpers/selectedXToString"
import { accordionStateUpdate } from "../../redux/actions/settings"
import { ROOT } from "../../const/globals"

const SheetSummary = ({
  project,
  settings,
  isResetForm = false,
  isExamOn,
  dispatch,
}) => {
  //=================================================================

  const onChange = (accordionState) => {
    const payload = { accordionState }
    dispatch(accordionStateUpdate(payload))
  }

  //-----------------------------------------------------------------

  const { accordionState, currencySymbol } = settings

  const { dateStatusCurrent, dateStatusNextPlanned, params } = project
  const { persons, nodes } = project.dates[dateStatusCurrent]
  // const { todos } = project.dates[dateStatusCurrent]

  let {
    title,
    objective,
    description,
    communicationTool,
    dataRepository,
    customer1,
    customer2,
    otherVIP,
    manager,
    controller,
    productOwner,
    agileMaster,
    respChange,
    respQuality,
    respRisk,
    respKnowledge,
    team,
    sponsor,
    membersSteeringCommittee,
  } = project.dates[dateStatusCurrent].setup

  let {
    expensesBudget,
    expensesActual,
    unresolvedIssues,
    satisfactionCustomer,
    satisfactionTeam,
    issuesChangeTL,
    issuesChangeCheck,
    issuesQualityTL,
    issuesQualityCheck,
    issuesRiskTL,
    issuesRiskCheck,
    issuesKnowledgeTL,
    issuesKnowledgeCheck,
    todos,
  } = project.dates[dateStatusCurrent].ratios

  // TODO: can be removed after API Change 2 → 3
  if (respChange === undefined) {
    respChange = ""
  }
  if (respQuality === undefined) {
    respQuality = ""
  }
  if (respRisk === undefined) {
    respRisk = ""
  }
  if (respKnowledge === undefined) {
    respKnowledge = ""
  }
  if (todos === undefined) {
    todos = ""
  }

  if (unresolvedIssues === undefined) {
    unresolvedIssues = 0
  }

  const dateStartDate = parseISO(
    project.dates[dateStatusCurrent].nodes[ROOT].fromWhen
  )

  const dateEndDate = parseISO(
    project.dates[dateStatusCurrent].nodes[ROOT].byWhen
  )

  const dateStatusCurrentDate = parseISO(dateStatusCurrent)

  const dateStart = format(dateStartDate, "yyyy-MM-dd")

  const dateEnd = format(dateEndDate, "yyyy-MM-dd")

  const degreeOfCompletion = nodes[ROOT].degree

  const timeBudget = differenceInBusinessDays(dateEndDate, dateStartDate) + 1

  const timeActual =
    differenceInBusinessDays(
      min([dateStatusCurrentDate, dateEndDate]),
      dateStartDate
    ) + 1

  expensesBudget = parseInt0(expensesBudget)
  expensesActual = parseInt0(expensesActual)
  satisfactionCustomer = parseInt0(satisfactionCustomer)
  satisfactionTeam = parseInt0(satisfactionTeam)

  const {
    pUnresolvedIssues,
    pDOCThroughDOXS,
    pDOCThroughDOTS,
    pSatisfactionCustomer,
    pSatisfactionTeam,
  } = params

  const degreeOfExpensesSpent =
    expensesBudget === 0
      ? 0
      : Math.round((expensesActual / expensesBudget) * 100)

  const degreeOfTimeSpent =
    timeBudget === 0 ? 0 : Math.round((timeActual / timeBudget) * 100)

  const expensesProjection =
    degreeOfCompletion === 0
      ? 0
      : Math.round((expensesActual / degreeOfCompletion) * 100)

  const timeProjection =
    degreeOfCompletion === 0
      ? 0
      : Math.round((timeActual / degreeOfCompletion) * 100)

  const DOCThroughDOXS =
    degreeOfExpensesSpent === 0
      ? 0
      : Math.round((degreeOfCompletion / degreeOfExpensesSpent) * 100)

  const DOCThroughDOTS =
    degreeOfTimeSpent === 0
      ? 0
      : Math.round((degreeOfCompletion / degreeOfTimeSpent) * 100)

  const isInverse = true

  //=================================================================
  return (
    <>
      <Heading as="h2" textStyle="h3">
        {title}
      </Heading>
      <Accordion
        onChange={onChange}
        index={accordionState}
        allowMultiple
        reduceMotion={true}
      >
        <AccordionItem>
          <AccordionButton>
            <Text color="blue.800" flex="1" textAlign="left">
              Objective & Description
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Box>
              <LabelTop>Objective</LabelTop>
              <Text>{objective}</Text>
            </Box>
            {description !== "" && (
              <Box>
                <LabelTop>Description</LabelTop>
                <Text>{description}</Text>
              </Box>
            )}
            <Flex>
              {communicationTool !== "" && (
                <Box w={["50%"]} pr={[1]}>
                  <LabelTop>Communication Tool</LabelTop>
                  <Text>{communicationTool}</Text>
                </Box>
              )}
              {dataRepository !== "" && (
                <Box w={["50%"]} pl={[1]}>
                  <LabelTop>Data Repository</LabelTop>
                  <Text>{dataRepository}</Text>
                </Box>
              )}
            </Flex>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Text color="blue.800" flex="1" textAlign="left">
              Customer(s), Persons & Team
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Flex>
              <Box w={["50%"]} pl={[0]} pr={[1]}>
                {customer1 !== "" && (
                  <>
                    <LabelTop>Customer 1</LabelTop>
                    <Text>{persons[customer1].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box w={["50%"]} pl={[1]} pr={[1]}>
                {customer2 !== "" && (
                  <>
                    <LabelTop>Customer 2</LabelTop>
                    <Text>{persons[customer2].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box w={["50%"]} pl={[1]} pr={[1]}>
                {otherVIP !== "" && (
                  <>
                    <LabelTop>Other VIP</LabelTop>
                    <Text>{persons[otherVIP].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box w={["50%"]} pl={[1]} pr={[0]}>
                {sponsor !== "" && (
                  <>
                    <LabelTop>Project Sponsor</LabelTop>
                    <Text>{persons[sponsor].nameDisplayLong}</Text>
                  </>
                )}
              </Box>
            </Flex>

            {membersSteeringCommittee.length > 0 && (
              <Box>
                <LabelTop>Members Project Steering Committee</LabelTop>
                <Text>
                  {selectedPersonsToString(persons, membersSteeringCommittee)}
                </Text>
              </Box>
            )}

            <Flex>
              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[0, 0, 0, 0]}
                pr={[1, 1, 1, 1]}
              >
                {manager !== "" && (
                  <>
                    <LabelTop>Project Manager</LabelTop>
                    <Text>{persons[manager].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[1, 1, 1, 1]}
              >
                {productOwner !== "" && (
                  <>
                    <LabelTop>Product Owner</LabelTop>
                    <Text>{persons[productOwner].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[1, 1, 1, 1]}
              >
                {controller !== "" && (
                  <>
                    <LabelTop>Project Controller</LabelTop>
                    <Text>{persons[controller].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[0, 0, 0, 0]}
              >
                {agileMaster !== "" && (
                  <>
                    <LabelTop>Agile Master</LabelTop>
                    <Text>{persons[agileMaster].nameDisplayLong}</Text>
                  </>
                )}
              </Box>
            </Flex>

            <Flex>
              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[0, 0, 0, 0]}
                pr={[1, 1, 1, 1]}
              >
                {respChange !== "" && (
                  <>
                    <LabelTop>Resp. Change Mgt</LabelTop>
                    <Text>{persons[respChange].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[1, 1, 1, 1]}
              >
                {respQuality !== "" && (
                  <>
                    <LabelTop>Resp. Quality Mgt</LabelTop>
                    <Text>{persons[respQuality].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[1, 1, 1, 1]}
              >
                {respRisk !== "" && (
                  <>
                    <LabelTop>Resp. Risk Mgt</LabelTop>
                    <Text>{persons[respRisk].nameDisplayLong}</Text>
                  </>
                )}
              </Box>

              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[0, 0, 0, 0]}
              >
                {respKnowledge !== "" && (
                  <>
                    <LabelTop>Resp. Knowledge Mgt</LabelTop>
                    <Text>{persons[respKnowledge].nameDisplayLong}</Text>
                  </>
                )}
              </Box>
            </Flex>

            {team.length > 0 && (
              <Box w={["100%"]} pl={[0]} pr={[0]}>
                <LabelTop>Project Team Members</LabelTop>
                <Text>{selectedPersonsToString(persons, team)}</Text>
              </Box>
            )}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Text color="blue.800" flex="1" textAlign="left">
              Dates
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Flex>
              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[0, 0, 0, 0]}
                pr={[1, 1, 1, 1]}
              >
                <Tooltip
                  label="The Start Date of the project. It can be entered on the Setup page."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Start Date</LabelTop>
                </Tooltip>
                <Text>{dateStart}</Text>
              </Box>

              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[0, 0, 1, 1]}
              >
                <Tooltip
                  label="The End Date of the project. It results from the project plan."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>End Date</LabelTop>
                </Tooltip>
                <Text>{dateEnd}</Text>
              </Box>
              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[0, 0, 1, 1]}
              >
                <Tooltip
                  label="All tasks will be evaluated on the Current Status Date. Automatic deadline evaluation takes place for all tasks that have a delivery date BEFORE the Current Status Date."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Current Status Date</LabelTop>
                </Tooltip>

                <Text>{format(parseISO(dateStatusCurrent), "yyyy-MM-dd")}</Text>
              </Box>
              <Box
                w={["50%", "50%", "25%", "25%"]}
                pl={[1, 1, 1, 1]}
                pr={[0, 0, 1, 1]}
              >
                <Tooltip
                  label="Forthcoming status date, if already planned and entered in Setup."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Planned Next Status Date</LabelTop>
                </Tooltip>
                <Text>
                  {dateStatusNextPlanned === ""
                    ? ""
                    : format(parseISO(dateStatusNextPlanned), "yyyy-MM-dd")}
                </Text>
              </Box>
            </Flex>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Text color="blue.800" flex="1" textAlign="left">
              Top Management TODOs, Significant Issues, and Ratios
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Flex wrap="wrap">
              <Flex
                wrap="wrap"
                w={["100%", "100%", "100%", "100%", "100%", "50%"]}
                order={[2, 2, 2, 2, 2, 1]}
              >
                <Box w={["50%", "50%", "50%", "25%"]} textAlign="right">
                  <Tooltip
                    label="SIGNIFICANT CHANGE MGT ISSUES: Do such issues of overall, tangible, material and severe dimension exist and have appropriate measures been taken?"
                    placement="top-start"
                    hasArrow
                  >
                    <LabelTop>Significant Change Mgt Issues</LabelTop>
                  </Tooltip>
                  <Flex justify="flex-end">
                    <TrafficLight
                      name="issuesChangeTL"
                      colorCode={issuesChangeTL}
                      mr={2}
                    />
                    <Text mr={4}>Issues</Text>
                    <Checkbox
                      name="issuesChangeCheck"
                      isChecked={issuesChangeCheck}
                    >
                      Measures
                    </Checkbox>
                  </Flex>
                </Box>

                <Box w={["50%", "50%", "50%", "25%"]} textAlign="right">
                  <Tooltip
                    label="SIGNIFICANT QUALITY ISSUES: Do such issues of overall, tangible, material and severe dimension exist and have appropriate measures been taken?"
                    placement="top-start"
                    hasArrow
                  >
                    <LabelTop>Significant Quality Issues</LabelTop>
                  </Tooltip>
                  <Flex justify="flex-end">
                    <TrafficLight
                      name="issuesQualityTL"
                      colorCode={issuesQualityTL}
                      mr={2}
                    />
                    <Text mr={4}>Issues</Text>
                    <Checkbox
                      name="issuesQualityCheck"
                      isChecked={issuesQualityCheck}
                    >
                      Measures
                    </Checkbox>
                  </Flex>
                </Box>

                <Box w={["50%", "50%", "50%", "25%"]} textAlign="right">
                  <Tooltip
                    label="SIGNIFICANT RISK ISSUES: Do such issues of overall, tangible, material and severe dimension exist and have appropriate measures been taken?"
                    placement="top-start"
                    hasArrow
                  >
                    <LabelTop>Significant Risk Issues</LabelTop>
                  </Tooltip>
                  <Flex justify="flex-end">
                    <TrafficLight
                      name="issuesRiskTL"
                      colorCode={issuesRiskTL}
                      mr={2}
                    />
                    <Text mr={4}>Issues</Text>
                    <Checkbox
                      name="issuesRiskCheck"
                      isChecked={issuesRiskCheck}
                    >
                      Measures
                    </Checkbox>
                  </Flex>
                </Box>

                <Box w={["50%", "50%", "50%", "25%"]} textAlign="right">
                  <Tooltip
                    label="SIGNIFICANT KNOWLEDGE ISSUES: Do such issues of overall, tangible, material and severe dimension exist and have appropriate measures been taken?"
                    placement="top-start"
                    hasArrow
                  >
                    <LabelTop>Significant Knowledge Issues</LabelTop>
                  </Tooltip>
                  <Flex justify="flex-end">
                    <TrafficLight
                      name="issuesKnowledgeTL"
                      colorCode={issuesKnowledgeTL}
                      mr={2}
                    />
                    <Text mr={4}>Issues</Text>
                    <Checkbox
                      name="issuesKnowledgeCheck"
                      isChecked={issuesKnowledgeCheck}
                    >
                      Measures
                    </Checkbox>
                  </Flex>
                </Box>
              </Flex>

              <Box
                w={["100%", "100%", "100%", "100%", "100", "50%"]}
                order={[1, 1, 1, 1, 1, 2]}
              >
                <Flex w="100%">
                  <Box w={["25%"]} textAlign="right">
                    <Tooltip
                      label="Top Management TODOs: These are TODOs for the Management that is hierarchical above the project. A project is usually embedded in an organization and sometimes the project manager cannot manage issues outside the project. In these cases, Top Management has to perform certain activities. Select from typical TODOs that are pre-installed or add further TODOs."
                      placement="top-start"
                      hasArrow
                      maxWidth={400}
                    >
                      <LabelTop>Management TODOs</LabelTop>
                    </Tooltip>
                  </Box>
                </Flex>

                {todos.length > 0 && (
                  <Flex w="100%" pl={16} pr={4}>
                    <Text>{selectedToString(todos)}</Text>
                  </Flex>
                )}
              </Box>
            </Flex>

            <Flex wrap="wrap">
              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="EXPENSES BUDGET: The expenses assigned to the project from Project Start to Project End."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Expenses Budget {currencySymbol}</LabelTop>
                </Tooltip>
                <Text>{expensesBudget}</Text>
              </Box>

              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="EXPENSES ACTUAL: The expenses already spent until the Current Status Date."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Expenses Actual {currencySymbol}</LabelTop>
                </Tooltip>
                <Text>{expensesActual}</Text>
              </Box>

              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="EXPENSES PROJECTION: The expenses amount that will likely be needed. It is computed as Expenses Projection = Expenses Actual / Degree of Completion."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Expenses Projection {currencySymbol}</LabelTop>
                </Tooltip>
                <Flex justify="flex-end">
                  <Text
                    h="24px"
                    w="60px"
                    border={isExamOn ? "2px solid goldenrod" : "none"}
                  >
                    {!isExamOn && expensesProjection}
                  </Text>
                </Flex>
              </Box>

              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="DEGREE OF EXPENSES SPENT %: Expenses Actual / Expenses Budget"
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Degree of Exp. Spent %</LabelTop>
                </Tooltip>
                <Flex justify="flex-end">
                  <Text
                    h="24px"
                    w="60px"
                    border={isExamOn ? "2px solid goldenrod" : "none"}
                  >
                    {!isExamOn && degreeOfExpensesSpent}
                  </Text>
                </Flex>
              </Box>

              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="DEGREE OF COMPLETION %: Degree by which the project is considered completed. The number is taken from the top row in the sheet below."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Degree of Completion %</LabelTop>
                </Tooltip>
                <Flex justify="flex-end">
                  <Text
                    h="24px"
                    w="60px"
                    border={isExamOn ? "2px solid goldenrod" : "none"}
                  >
                    {!isExamOn && degreeOfCompletion}
                  </Text>
                </Flex>
              </Box>

              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="DOC/DOXS %: Degree of Completion / Degree of Expenses Spent * 100."
                  placement="top-end"
                  hasArrow
                >
                  <LabelTop>DOC/DOXS %</LabelTop>
                </Tooltip>

                <Flex justify="flex-end" align="center">
                  <Text
                    mr={2}
                    h="24px"
                    w="60px"
                    border={isExamOn ? "2px solid goldenrod" : "none"}
                  >
                    {!isExamOn && DOCThroughDOXS}
                  </Text>
                  <TrafficLight
                    isExamOn={isExamOn}
                    colorCode={colorCodeCalc(DOCThroughDOXS, pDOCThroughDOXS)}
                    position="relative"
                    top="-5px"
                  />
                </Flex>
              </Box>

              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="SATISFACTION CUSTOMER %: Needs to be researched outside of this app."
                  placement="top-end"
                  hasArrow
                >
                  <LabelTop>Satisf. Customer %</LabelTop>
                </Tooltip>
                <Flex justify="flex-end" align="center">
                  <Text mr={2}>{satisfactionCustomer}</Text>
                  <TrafficLight
                    isExamOn={isExamOn}
                    colorCode={colorCodeCalc(
                      satisfactionCustomer,
                      pSatisfactionCustomer
                    )}
                    position="relative"
                    top="-5px"
                  />
                </Flex>
              </Box>
            </Flex>

            <Flex wrap="wrap">
              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="TIME BUDGET: Business days between the Project Start Date and the Project End Date."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Time Budget d</LabelTop>
                </Tooltip>
                <Text>{timeBudget}</Text>
              </Box>

              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="TIME ACTUAL: Business days between the Project Start Date and Current Status Date."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Time Actual d</LabelTop>
                </Tooltip>
                <Text>{timeActual}</Text>
              </Box>

              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="TIME PROJECTION: The time in business days, that will likely be needed. It is computed as Time Projection = Time Actual / Degree of Completion."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Time Projection d</LabelTop>
                </Tooltip>
                <Flex justify="flex-end">
                  <Text
                    h="24px"
                    w="60px"
                    border={isExamOn ? "2px solid goldenrod" : "none"}
                  >
                    {!isExamOn && timeProjection}
                  </Text>
                </Flex>
              </Box>

              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="DEGREE OF TIME SPENT: Time Actual / Time Budget."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Degree of Time Spent %</LabelTop>
                </Tooltip>
                <Flex justify="flex-end">
                  <Text
                    h="24px"
                    w="60px"
                    border={isExamOn ? "2px solid goldenrod" : "none"}
                  >
                    {!isExamOn && degreeOfTimeSpent}
                  </Text>
                </Flex>
              </Box>

              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="UNRESOLVED ISSUES: Number (Π for pieces) of deliverables in sheet marked as unresolved."
                  placement="top-start"
                  hasArrow
                >
                  <LabelTop>Unresolved Issues Π</LabelTop>
                </Tooltip>
                <Flex justify="flex-end" align="center">
                  <Text mr={2}>{unresolvedIssues}</Text>
                  <TrafficLight
                    isExamOn={isExamOn}
                    colorCode={colorCodeCalc(
                      unresolvedIssues,
                      pUnresolvedIssues,
                      isInverse
                    )}
                    position="relative"
                    top="-5px"
                  />
                </Flex>
              </Box>

              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="DOC/DOTS %: Degree of Completion / Degree of Time Spent * 100"
                  placement="top-end"
                  hasArrow
                >
                  <LabelTop>DOC/DOTS %</LabelTop>
                </Tooltip>
                <Flex justify="flex-end" align="center">
                  <Text
                    mr={2}
                    h="24px"
                    w="60px"
                    border={isExamOn ? "2px solid goldenrod" : "none"}
                  >
                    {!isExamOn && DOCThroughDOTS}
                  </Text>
                  <TrafficLight
                    isExamOn={isExamOn}
                    colorCode={colorCodeCalc(DOCThroughDOTS, pDOCThroughDOTS)}
                    position="relative"
                    top="-5px"
                  />
                </Flex>
              </Box>

              <Box w={["50%", "50%", "25%", "25%", "12.5%"]} textAlign="right">
                <Tooltip
                  label="SATISFACTION TEAM %: Needs to be researched outside of this app."
                  placement="top-end"
                  hasArrow
                >
                  <LabelTop>Satisfaction Team %</LabelTop>
                </Tooltip>
                <Flex justify="flex-end" align="center">
                  <Text mr={2}>{satisfactionTeam}</Text>
                  <TrafficLight
                    isExamOn={isExamOn}
                    colorCode={colorCodeCalc(
                      satisfactionTeam,
                      pSatisfactionTeam
                    )}
                    position="relative"
                    top="-5px"
                  />
                </Flex>
              </Box>
            </Flex>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton
            as={"a"}
            color="inherit"
            _hover={{ color: "inherit" }}
          >
            <Flex flex="1" justify="space-between">
              <Text cursor="pointer" color="blue.800" flex="1" textAlign="left">
                Gantt-Chart
              </Text>
              <GanttChartResize />
            </Flex>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <GanttChart />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    project: state.project,
    settings: state.settings,
    isExamOn: state.app.isExamOn,
    isResetForm: state.project.isResetForm,
  }
}

export default connect(mapStateToProps)(SheetSummary)
