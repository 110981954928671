import { Flex, Box, Spacer, Tooltip } from "@chakra-ui/react"
import * as WIDTH from "../../const/widths"

import { BiBlock } from "react-icons/bi"

const SheetHeader = ({ params }) => {
  //=================================================================
  const { isCommentVertical } = params
  //=================================================================
  return (
    <Flex mb={4} mr={2}>
      <Tooltip
        label="IGNORE: Click to ignore issues. The time spent will still be counted in the accumulation, but traffic light colors will not be considered in the aggregation."
        placement="top-start"
        hasArrow
      >
        <Flex
          w={WIDTH.IGNORE}
          px={0}
          color="white"
          bg="blue.400"
          justify="space-around"
        >
          <BiBlock />
        </Flex>
      </Tooltip>

      <Spacer />

      <Tooltip
        label="POSITION: Shows the hierarchy of the deliverable."
        placement="top-start"
        hasArrow
      >
        <Box w={WIDTH.POSITION} color="white" bg="blue.400" px={1}>
          POSITION
        </Box>
      </Tooltip>

      <Spacer />

      <Tooltip
        label="UNRESOLVED: Mark unresolved issues. The number of unresolved issues will be shown under the Important Ratios."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.UNRESOLVED}
          bg="blue.400"
          color="white"
          textAlign="center"
        >
          ?
        </Box>
      </Tooltip>

      <Spacer />

      <Tooltip
        label="WHO: The sole responsible person for the deliverable. Enter a new person or select an existing one. Remove with the little ×."
        placement="top"
        hasArrow
      >
        <Box w={WIDTH.WHO} bg="blue.400" color="white" px={1}>
          WHO
        </Box>
      </Tooltip>

      <Spacer />

      <Tooltip
        label="DELIVERS WHAT: The deliverable that is the resulting output."
        placement="top"
        hasArrow
      >
        <Box w={WIDTH.WHAT} bg="blue.400" color="white" px={1}>
          DELIVERS WHAT
        </Box>
      </Tooltip>

      <Spacer />
      {/*
      <Tooltip
        label="Start date of a deliverable. If the deliverable is dependent on other deliverables the start date is the latest promised date of these deliverables."
        placement="top"
        hasArrow
      >
        <Box w={WIDTH.WHEN} bg="pink.600" color="white" px={1}>
          FROM WHEN
        </Box>
      </Tooltip>

      <Spacer /> */}

      <Tooltip
        label="BY WHEN: The promised delivery date. A down pointing arrow will fixate the due date, a right pointing arrow will allow for a recalculation based on duration. "
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.WHEN}
          bg="blue.400"
          color="white"
          px={1}
          textAlign="right"
        >
          BY WHEN
        </Box>
      </Tooltip>

      <Spacer />

      <Tooltip
        label="TO WHOM: Person who receives the deliverable and then has to evaluate its quality."
        placement="top"
        hasArrow
      >
        <Box w={WIDTH.WHO} bg="blue.400" color="white" px={1}>
          TO WHOM
        </Box>
      </Tooltip>

      <Spacer />

      <Tooltip
        label="SPENT: The time that has already been spent working on the deliverable."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.SPENT}
          bg="blue.400"
          color="white"
          px={1}
          textAlign="right"
        >
          SPENT
        </Box>
      </Tooltip>

      <Spacer />

      <Tooltip
        label="DEGREE OF COMPLETION (%): A reasonable percentage number that reflects the degree of completion of the deliverable."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.DEGREE}
          bg="blue.400"
          color="white"
          px={1}
          textAlign="right"
        >
          %
        </Box>
      </Tooltip>

      <Spacer />

      <Tooltip
        label="DL — DEADLINE: A traffic light color evaluating if the deliverable has been delivered on time before the deadline to 100%. The traffic light color is set automatically."
        placement="top"
        hasArrow
      >
        <Box w={WIDTH.DEADLINE} color="white" bg="blue.400" textAlign="center">
          DL
        </Box>
      </Tooltip>

      <Spacer />

      <Tooltip
        label="FC — FORECAST: A forecast if the promised delivery date will be met."
        placement="top"
        hasArrow
      >
        <Box w={WIDTH.FORECAST} color="white" bg="blue.400" textAlign="center">
          FC
        </Box>
      </Tooltip>

      <Spacer />

      <Tooltip
        label="Q — QUALITY: A traffic light color with which the receiver (TO WHOM) evaluates the quality of the deliverable. A red quality requires the setup of a new entry for improving the quality to green."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.QUALITY}
          bg="blue.400"
          color="white"
          px={0}
          textAlign="center"
        >
          Q
        </Box>
      </Tooltip>

      <Spacer />

      {isCommentVertical && (
        <Tooltip
          label="COMMENT: Every traffic light color other than green requires a comment."
          placement="top"
          hasArrow
        >
          <Box w={WIDTH.COMMENT} bg="blue.400" color="white" px={1}>
            COMMENT (ISSUE or POTENTIAL FIX)
          </Box>
        </Tooltip>
      )}

      {isCommentVertical && <Spacer />}

      <Tooltip
        label="OK: Click OK or press ↵ Return to take over text input."
        placement="top-end"
        hasArrow
      >
        <Box w={WIDTH.OK} color="white" bg="grey.500" textAlign="center">
          OK
        </Box>
      </Tooltip>

      <Spacer />

      <Tooltip
        label="FOLD & MOVE: Fold or unfold your rows or move them in, out, down or up."
        placement="top-end"
        hasArrow
      >
        <Box
          w={WIDTH.MOVE}
          color="white"
          bg="grey.500"
          px={1}
          textAlign="center"
        >
          MOVE
        </Box>
      </Tooltip>

      <Spacer />

      <Tooltip
        label="ADD & REMOVE: Add new rows after an existing one or remove any you want."
        placement="top-end"
        hasArrow
      >
        <Box w={WIDTH.PM} color="white" bg="grey.500" px={1} textAlign="center">
          PM
        </Box>
      </Tooltip>

      {/* <Spacer />

      <Box w={WIDTH.SELECT} px={1} color="white" bg="grey.200">
        S
      </Box> */}
    </Flex>
  )
}

export default SheetHeader
