/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Transforms a positon array into a position string
 * @param {array} position
 * @return {string} positionString
 */
const posToStr = (position) =>
  position.map((element) => element.toString().padStart(2, "0")).join(".")

export default posToStr
