import React, { useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { chakra, Flex, Input, Tooltip, Box, Avatar } from "@chakra-ui/react"
import { LabelTop } from "../../componentsWithChakra"
import {
  personAdd,
  personRemove,
  personUpdate,
} from "../../redux/actions/project"

import objDeepTrim from "../../functions/utils/objDeepTrim"

import { BiPlus, BiMinus } from "react-icons/bi"
import {
  NAME_MESSAGE,
  NAME_REGEX,
  EMAIL_MESSAGE,
  EMAIL_REGEX,
} from "../../const/messages"

const Person = ({
  person,
  isLast,
  isLocked,
  isResetForm = false,
  dispatch,
}) => {
  //=================================================================
  const { handleSubmit, register, reset, errors } = useForm()
  const inputRef = useRef(null)

  //-----------------------------------------------------------------
  const {
    rId,
    academicTitle,
    nameFirst,
    nameLast,
    email,
    phone,
    url,
    company,
    jobFunction,
    projectRole,
    avatar,
  } = person

  //-----------------------------------------------------------------
  useEffect(() => {
    if (isResetForm) {
      reset()
    }
  }, [isResetForm, reset, person])

  //-----------------------------------------------------------------
  const onSubmit = (data) => {
    inputRef.current.focus()
    data.rId = rId
    objDeepTrim(data)
    const payload = { rId, data }
    dispatch(personUpdate(payload))
  }

  // ----------------------------------------------------------------
  const onClickPlus = () => {
    dispatch(personAdd())
  }
  const onClickMinus = () => {
    const payload = { rId }
    dispatch(personRemove(payload))
  }

  //=================================================================
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Flex align="center">
        <Avatar
          size="xl"
          name={nameFirst + " " + nameLast}
          src={avatar}
          mr={4}
        />

        <Flex wrap="wrap">
          <Box
            w={["50%", "50%", "33.33%", "12%"]}
            pl={[0, 0, 0, 0]}
            pr={[1, 1, 1, 1]}
          >
            <Tooltip
              label="You may want to enter an academic or other title."
              placement="top-start"
              hasArrow
            >
              <LabelTop>Title</LabelTop>
            </Tooltip>
            <Input
              name="academicTitle"
              defaultValue={academicTitle}
              ref={register}
              isDisabled={isLocked}
            />
          </Box>
          <Box
            w={["50%", "50%", "33.33%", "22%"]}
            pl={[1, 1, 1, 1]}
            pr={[1, 1, 1, 1]}
          >
            <Tooltip
              label="Enter the first name."
              placement="top-start"
              hasArrow
            >
              <LabelTop>First Name</LabelTop>
            </Tooltip>
            <Tooltip
              isOpen={errors.nameFirst}
              label={errors.nameFirst ? errors.nameFirst.message : ""}
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                name="nameFirst"
                defaultValue={nameFirst}
                ref={register({
                  required: "Invalid: Please enter at least one character.",
                  minLength: 1,
                  pattern: {
                    value: NAME_REGEX,
                    message: NAME_MESSAGE,
                  },
                })}
                isDisabled={isLocked}
              />
            </Tooltip>
          </Box>
          <Box
            w={["50%", "50%", "33.33%", "22%"]}
            pl={[1, 1, 1, 1]}
            pr={[1, 1, 1, 1]}
          >
            <Tooltip
              label="Enter the last name."
              placement="top-start"
              hasArrow
            >
              <LabelTop>Last Name</LabelTop>
            </Tooltip>

            <Tooltip
              isOpen={errors.nameLast}
              label={errors.nameLast ? errors.nameLast.message : ""}
              placement="bottom-start"
              hasArrow
              bg="red.500"
            >
              <Input
                name="nameLast"
                defaultValue={nameLast}
                ref={register({
                  required: "Invalid: Please enter at least one character.",
                  minLength: 1,
                  pattern: {
                    value: NAME_REGEX,
                    message: NAME_MESSAGE,
                  },
                })}
                isDisabled={isLocked}
              />
            </Tooltip>
          </Box>
          <Box
            w={["50%", "50%", "33.33%", "22%"]}
            pl={[1, 1, 1, 1]}
            pr={[1, 1, 0, 1]}
          >
            <Tooltip
              label="You can enter the company the person is working for."
              placement="top"
              hasArrow
            >
              <LabelTop>Company</LabelTop>
            </Tooltip>
            <Input
              name="company"
              defaultValue={company}
              ref={register}
              isDisabled={isLocked}
            />
          </Box>
          <Box
            w={["50%", "50%", "33.33%", "22%"]}
            pl={[1, 1, 0, 1]}
            pr={[0, 0, 0, 0]}
          >
            <Tooltip
              label="You can enter the function or job title of the person in the company."
              placement="top"
              hasArrow
            >
              <LabelTop>Function / Job Title</LabelTop>
            </Tooltip>
            <Input
              name="jobFunction"
              defaultValue={jobFunction}
              ref={register}
              isDisabled={isLocked}
            />
          </Box>
          <Box
            w={["50%", "50%", "33.33%", "12%"]}
            pl={[0, 0, 0, 0]}
            pr={[1, 1, 1, 1]}
          >
            <Tooltip
              label="If you provide a URL to your profile picture it will be displayed. Otherwise the initials are shown."
              placement="top-start"
              hasArrow
            >
              <LabelTop>Avatar-URL</LabelTop>
            </Tooltip>
            <Input
              color="grey.400"
              name="avatar"
              defaultValue={avatar}
              ref={register}
              isDisabled={isLocked}
            />
          </Box>
          <Box
            w={["50%", "50%", "33.33%", "22%"]}
            pl={[1, 1, 1, 1]}
            pr={[1, 1, 1, 1]}
          >
            <Tooltip
              label="You can enter the role the person has in this project. The role can be one of the pre-defined roles from the Setup page or any other role that is useful in this project."
              placement="top"
              hasArrow
            >
              <LabelTop>Project Role</LabelTop>
            </Tooltip>
            <Input
              name="projectRole"
              defaultValue={projectRole}
              ref={register}
              isDisabled={isLocked}
            />
          </Box>
          <Box
            w={["50%", "50%", "33.33%", "22%"]}
            pl={[1, 1, 1, 1]}
            pr={[1, 1, 1, 1]}
          >
            <Tooltip label="Enter the email." placement="top" hasArrow>
              <LabelTop>Email</LabelTop>
            </Tooltip>

            <Tooltip
              isOpen={errors.email}
              label={errors.email ? errors.email.message : ""}
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                name="email"
                defaultValue={email}
                ref={register({
                  required: false,
                  pattern: {
                    value: EMAIL_REGEX,
                    message: EMAIL_MESSAGE,
                  },
                })}
                isDisabled={isLocked}
              />
            </Tooltip>
          </Box>
          <Box
            w={["50%", "50%", "33.33%", "22%"]}
            pl={[1, 1, 1, 1]}
            pr={[1, 1, 1, 1]}
          >
            <Tooltip
              label="You may enter the phone number. If it is a personal phone number make sure that the person agrees to sharing this information."
              placement="top"
              hasArrow
            >
              <LabelTop>Phone</LabelTop>
            </Tooltip>
            <Input
              name="phone"
              defaultValue={phone}
              ref={register}
              isDisabled={isLocked}
            />
          </Box>
          <Box
            w={["50%", "50%", "33.33%", "22%"]}
            pl={[1, 1, 1, 1]}
            pr={[0, 0, 0, 0]}
          >
            <Tooltip
              label="You can enter a webpage or any other URL of this person as a reference."
              placement="top"
              hasArrow
            >
              <LabelTop>URL</LabelTop>
            </Tooltip>
            <Input
              name="url"
              defaultValue={url}
              ref={register}
              isDisabled={isLocked}
            />
          </Box>
        </Flex>
      </Flex>
      {!isLocked && (
        <Box
          width={"80px"}
          justify="space-between"
          px={2}
          mb={4}
          align="flex-start"
          ml="108px"
        >
          <chakra.button type="submit" ref={inputRef} outline="none" mr={2}>
            OK
          </chakra.button>
          {!isLast ? (
            <chakra.button
              type="button"
              onClick={onClickMinus}
              cursor="pointer"
              outline="none"
            >
              <Box position="relative" top="-4px">
                <BiMinus />
              </Box>
            </chakra.button>
          ) : (
            <chakra.span w="16px"></chakra.span>
          )}
          {isLast ? (
            <chakra.button
              type="button"
              onClick={onClickPlus}
              cursor="pointer"
              outline="none"
            >
              <Box position="relative" top="-2px">
                <BiPlus />
              </Box>
            </chakra.button>
          ) : (
            <chakra.span w="16px"></chakra.span>
          )}
        </Box>
      )}
    </form>
  )
}

export default Person
