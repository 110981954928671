import { copyDeep } from "../utils/objectCopyDeep"
import nodesRowsGen from "./nodesRowsGen"
import { ROOT } from "../../const/globals"

/**
 * © Prof. Dr. Ulrich Anders
 *
 * Removes from arr the elements of toBeRemovedArr
 * @param {array} array
 * @param {array} toBeRemovedArr
 * @returns {array} arrayRemaining
 *
 */
export function arrayRemoveFrom(arr, toBeRemovedArr) {
  let arrRemaining = arr.filter(
    (element) => !toBeRemovedArr.some((value) => value === element)
  )
  return arrRemaining
}

/**
 * (C) Jasper Anders
 *
 * Deletes all nodes from the nodes tree under a certain nId
 * @param {object} nodes - nodes tree
 * @param {string} nId - nodes Id
 * @returns {object} remaining nodes
 */
export function nodesDelete(nodes, nId) {
  const nodesNew = copyDeep(nodes)
  const nodesToDelete = nodesRowsGen(nodes, nId, true)
  const parent = nodesNew[nodes[nId].pId]
  const indexNIdChildOfPId = parent.children.indexOf(nId)
  parent.children.splice(indexNIdChildOfPId, 1)

  nodesToDelete.forEach((nodeId) => {
    delete nodesNew[nodeId]
  })

  // all remaining nodes
  const nodesAll = nodesRowsGen(nodesNew, ROOT, true)
  nodesAll.forEach((nId) => {
    nodesNew[nId].precedents = arrayRemoveFrom(
      nodesNew[nId].precedents,
      nodesToDelete
    )
    nodesNew[nId].dependents = arrayRemoveFrom(
      nodesNew[nId].dependents,
      nodesToDelete
    )
  })
  return nodesNew
}

export default nodesDelete
