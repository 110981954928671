import produce from "immer"
import cloneDeep from "lodash.clonedeep"
// import { deepDiff } from "../../functions/utils/deepDiff"

import merge from "lodash.merge"
import isWeekend from "date-fns/isWeekend"
import addBusinessDays from "date-fns/addBusinessDays"
import startOfDay from "date-fns/startOfDay"
import parseISO from "date-fns/parseISO"
import isAfter from "date-fns/isAfter"
import isBefore from "date-fns/isBefore"
import isSameDay from "date-fns/isSameDay"
import format from "date-fns/format"

import {
  projectUpload,
  resetProject,
  resetSheet,
  selectStatusUpdate,
  dateStatusCurrentUpdate,
  dateStatusLatestDelete,
  personAdd,
  personRemove,
  personUpdate,
  selectPersonUpdate,
  SelectPersonsUpdate,
  SelectTodosUpdate,
  rowCreate,
  rowDelete,
  rowMoveIn,
  rowMoveOut,
  rowMoveUpDown,
  rowFold,
  rowIgnore,
  rowUnresolved,
  rowUpdate,
  rowForecast,
  rowQuality,
  isByWhenPinnedUpdate,
  isHighlightedUpdate,
  isDetailsOnUpdate,
  isNameFirstPreferredUpdate,
  formRatiosUpdate,
  issueCheckUpdate,
  issueTLUpdate,
  formDatesUpdate,
  formSetupUpdate,
  formParamsUpdate,
  messageFromProjectDelete,
} from "../actions/project"

// import nodesPositionsGen from "../../functions/nodes/nodesPositionsGen"
// import { cleanDependentsAll } from "../../functions/nodes/nodesDependencies"
// import {
//   completionAccPipe,
//   nodesDeadlinePipe,
//   nodesStatusAcc,
// } from "../../functions/nodes/nodesAccumulations"

// import {
//   byWhenFctSet,
//   nodesTreeSpanSlackPipe,
//   nodesFromByWhenPipeWrapper,
//   nodesByWhenAccumulateDependencyRestriction,
// } from "../../functions/timeHandler/timeHandler"

// import { nodesTreeDepsClean } from "../../functions/nodes/nodesTreeDepsClean"
// import { nodesListDurationsCalc } from "../../functions/timeHandler/timeHandler2"

import { nodesDependenciesSet } from "../../functions/nodes/nodesDependencies"

import { projectInitialStateSet } from "../../redux/states/projectInitialState"

import nodeCreate from "../../functions/nodes/nodeCreate"
import { nodesTreeDepsCircularCheck } from "../../functions/nodes/nodesTreeDepsClean"
import nodeNewAddAfter from "../../functions/nodes/nodeNewAddAfter"
import nodesDelete from "../../functions/nodes/nodesDelete"
import nodesRowsGen from "../../functions/nodes/nodesRowsGen"
import { moveIn, moveOut, moveUpDown } from "../../functions/nodes/nodeMoves"
import { nodesTreeDownUp } from "../../functions/nodes/nodesAccumulations2"

import personCreate from "../../functions/persons/personCreate"
import { personsDisplayNameGen } from "../../functions/persons/personsDisplayNames"
import {
  nameDisplayShortGen,
  nameDisplayLongGen,
} from "../../functions/persons/nameDisplayGen"
import {
  personRemoveFromNodes,
  personRemoveFromSetup,
} from "../../functions/persons/personRemoveFromX"
import namesGen from "../../functions/persons/namesGen"

import {
  startOfBusinessDay,
  endOfBusinessDay,
  startOfBusinessDayISO,
  endOfBusinessDayISO,
} from "../../functions/timeHandler/bordersOfBusinessDay"

import { durStrToDur } from "../../functions/timeHandler/durations"

import locToISO from "../../functions/timeHandler/locToISO"

import nanoId from "../../functions/utils/nanoid"

import { ROOT } from "../../const/globals"

const project = (slice = [], action) => {
  //=================================================================
  let sliceNext = slice

  const { payload } = action

  let dateStatusCurrent = sliceNext && sliceNext.dateStatusCurrent

  if (sliceNext && sliceNext.isResetForm) {
    sliceNext = produce(sliceNext, (sliceDraft) => {
      sliceDraft.isResetForm = false
    })
  }

  if (
    sliceNext &&
    sliceNext.messageFromProject &&
    sliceNext.messageFromProject !== ""
  ) {
    sliceNext = produce(sliceNext, (sliceDraft) => {
      sliceDraft.messageFromProject = ""
    })
  }

  //================================================================
  switch (action.type) {
    // UPLOAD --------------------------------------------------------
    case projectUpload.type:
      if (payload.warning === "lowerAPI") {
        sliceNext = produce(sliceNext, (sliceDraft) => {
          sliceDraft = merge(sliceDraft, payload.project)
        })
      } else {
        // TODO: do not understand, why this cannot go via produce()???
        // It seems that you need to keep the proxy values somehow.
        sliceNext = payload.project
      }

      return sliceNext
    // RESET --------------------------------------------------------
    case resetProject.type:
      sliceNext = projectInitialStateSet()
      sliceNext.isResetForm = true
      return sliceNext

    case resetSheet.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        let theDay = isWeekend(new Date(Date.now()))
          ? addBusinessDays(new Date(Date.now()), 1)
          : new Date(Date.now())

        let _node1 = nodeCreate({
          pId: ROOT,
          fromWhen: startOfBusinessDayISO(theDay, "9"),
          byWhen: endOfBusinessDayISO(theDay, "17"),
        })

        let _root = nodeCreate({
          nId: ROOT,
          position: [],
          children: [_node1.nId],
          fromWhen: _node1.fromWhen,
          byWhen: _node1.byWhen,
        })

        sliceDraft.dates[dateStatusCurrent].nodes = {}
        sliceDraft.dates[dateStatusCurrent].nodes = {
          _root,
          [_node1.nId]: _node1,
        }
        sliceDraft.dates[dateStatusCurrent].rows = [ROOT, _node1.nId]

        // initial byWhenFct, span, projection, slack
        // sliceDraft.dates[dateStatusCurrent].nodes = nodesListDurationsCalc(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // initial byWhenFct, span, projection, slack
        sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.dates[dateStatusCurrent].nodes,
          ROOT,
          dateStatusCurrent,
          {
            doPositions: false,
            doDependenciesClean: false,
            doFromByWhens: true,
            doTrafficLights: false,
            doEarliestLatest: true,
          }
        )

        sliceDraft.isResetForm = true
      })

      return sliceNext
    // DATES --------------------------------------------------------
    case dateStatusCurrentUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dateStatusCurrent = payload.dateStatusCurrent
      })
      return sliceNext

    case dateStatusLatestDelete.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        const dateStatusLast = sliceDraft.datesStatusList[0]
        sliceDraft.datesStatusList.shift()
        sliceDraft.dateStatusCurrent = sliceDraft.datesStatusList[0]
        delete sliceDraft.dates[dateStatusLast]
      })
      return sliceNext

    // PERSONS --------------------------------------------------------
    case personAdd.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        const rId = nanoId()
        sliceDraft.dates[dateStatusCurrent].persons[rId] = personCreate(rId)
      })
      return sliceNext

    case personRemove.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        delete sliceDraft.dates[dateStatusCurrent].persons[payload.rId]
        sliceDraft.dates[dateStatusCurrent].nodes = personRemoveFromNodes(
          sliceDraft.dates[dateStatusCurrent].nodes,
          payload.rId
        )
        sliceDraft.dates[dateStatusCurrent].setup = personRemoveFromSetup(
          sliceDraft.dates[dateStatusCurrent].setup,
          payload.rId
        )

        sliceDraft.messageFromProject =
          "Warning: The person you removed might have been responsible for certain roles or deliverables. Please check for not assigned spaces."

        sliceDraft.isResetForm = true
      })
      return sliceNext

    case personUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].persons[payload.rId] = payload.data

        sliceDraft.dates[dateStatusCurrent].persons[
          payload.rId
        ].nameDisplayShort = nameDisplayShortGen(
          payload.data.nameFirst,
          payload.data.nameLast,
          sliceDraft.params.isNameFirstPreferred
        )
        sliceDraft.dates[dateStatusCurrent].persons[
          payload.rId
        ].nameDisplayLong = nameDisplayLongGen(
          payload.data.nameFirst,
          payload.data.nameLast,
          payload.data.academicTitle
        )

        sliceDraft.isResetForm = true
      })
      return sliceNext

    case selectPersonUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        if (payload.isNew) {
          // create a new person
          const rId = nanoId()
          sliceDraft.dates[dateStatusCurrent].persons[rId] = personCreate(rId)
          const names = namesGen(
            payload.personName,
            sliceDraft.params.isNameFirstPreferred
          )

          sliceDraft.dates[dateStatusCurrent].persons[rId].nameFirst =
            names.nameFirst
          sliceDraft.dates[dateStatusCurrent].persons[rId].nameLast =
            names.nameLast
          sliceDraft.dates[dateStatusCurrent].persons[rId].nameDisplayShort =
            names.nameDisplayShort
          sliceDraft.dates[dateStatusCurrent].persons[rId].nameDisplayLong =
            names.nameDisplayLong

          // add label
          payload.value = rId
        }

        // sId is either "_setup" or contains the nId
        if (payload.sId === "_setup") {
          sliceDraft.dates[dateStatusCurrent].setup[payload.name] =
            payload.value
        } else {
          sliceDraft.dates[dateStatusCurrent].nodes[payload.sId][payload.name] =
            payload.value
        }
      })
      return sliceNext

    case SelectPersonsUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        let selected = payload.selected.map((person) => {
          if (person.__isNew__) {
            const rId = nanoId()
            sliceDraft.dates[dateStatusCurrent].persons[rId] = personCreate(rId)
            const names = namesGen(
              person.label.trim(),
              sliceDraft.params.isNameFirstPreferred
            )

            sliceDraft.dates[dateStatusCurrent].persons[rId].nameFirst =
              names.nameFirst
            sliceDraft.dates[dateStatusCurrent].persons[rId].nameLast =
              names.nameLast
            sliceDraft.dates[dateStatusCurrent].persons[rId].nameDisplayShort =
              names.nameDisplayShort
            sliceDraft.dates[dateStatusCurrent].persons[rId].nameDisplayLong =
              names.nameDisplayLong
            return { value: rId, label: names.nameDisplayShort }
          }
          return person
        })
        sliceDraft.dates[dateStatusCurrent].setup[payload.name] = selected
      })
      return sliceNext

    case SelectTodosUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        let selected = payload.selected.map((todo) => {
          if (todo.__isNew__) {
            const tId = nanoId()
            const todoNew = { value: tId, label: todo.label.trim() }
            sliceDraft.dates[dateStatusCurrent].todosAdded.push(todoNew)
            return todoNew
          }
          return todo
        })
        sliceDraft.dates[dateStatusCurrent].ratios[payload.name] = selected
      })
      return sliceNext

    // ROWS --------------------------------------------------------
    case rowCreate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].nodes = nodeNewAddAfter(
          sliceDraft.dates[dateStatusCurrent].nodes,
          payload.nIdBefore
        )

        sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.dates[dateStatusCurrent].nodes,
          ROOT,
          dateStatusCurrent
        )

        // let nodesIn = cloneDeep(sliceDraft.dates[dateStatusCurrent].nodes)
        // let nodesOut = nodesTreeDownUp(nodesIn, ROOT, dateStatusCurrent)

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesPositionsGen(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = completionAccPipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // console.log(
        //   "rowCreate different? ",
        //   deepDiff({ ...sliceDraft.dates[dateStatusCurrent].nodes }, nodesOut)
        // )

        sliceDraft.dates[dateStatusCurrent].rows = nodesRowsGen(
          sliceDraft.dates[dateStatusCurrent].nodes
        )
      })
      return sliceNext

    case rowDelete.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].nodes = nodesDelete(
          sliceDraft.dates[dateStatusCurrent].nodes,
          payload.nId,
          true
        )

        sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.dates[dateStatusCurrent].nodes,
          ROOT,
          dateStatusCurrent
        )

        // let nodesIn = cloneDeep(sliceDraft.dates[dateStatusCurrent].nodes)
        // let nodesOut = nodesTreeDownUp(nodesIn, ROOT, dateStatusCurrent)

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesPositionsGen(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesFromByWhenPipeWrapper(
        //   sliceDraft.dates[dateStatusCurrent].nodes,
        //   ROOT
        // )

        // // needed, because potentially a pinned dependent could have been deleted.
        // // In that case byWhen and byWhenLatest have to be re-calculated
        // sliceDraft.dates[
        //   dateStatusCurrent
        // ].nodes = nodesByWhenAccumulateDependencyRestriction(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // // needed because span and slack change in _root
        // sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeSpanSlackPipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = completionAccPipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // // needed because deadline traffic light could change because of deletion
        // sliceDraft.dates[dateStatusCurrent].nodes = nodesDeadlinePipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes,
        //   ROOT,
        //   dateStatusCurrent
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesStatusAcc(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // console.log(
        //   "rowDelete different? ",
        //   deepDiff({ ...sliceDraft.dates[dateStatusCurrent].nodes }, nodesOut)
        // )

        sliceDraft.dates[dateStatusCurrent].rows = nodesRowsGen(
          sliceDraft.dates[dateStatusCurrent].nodes
        )
      })
      return sliceNext

    case rowUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].nodes[payload.nId] = {
          ...sliceDraft.dates[dateStatusCurrent].nodes[payload.nId],
          ...payload.data,
        }

        // Update Precedents
        if (typeof payload.data.precedentsStr !== "undefined") {
          // save in case of circular dependencies
          const nodesTemp = sliceDraft.dates[dateStatusCurrent].nodes

          // set new precedents and dependents
          // does not allow ancestors, children or children's children to be precedents
          sliceDraft.dates[dateStatusCurrent].nodes = nodesDependenciesSet(
            sliceDraft.dates[dateStatusCurrent].nodes,
            payload.nId,
            payload.data.precedentsStr
          )

          // console.log("rowUpdate|precedentsStr: ", {
          //   nodes: sliceDraft.dates[dateStatusCurrent].nodes,
          // })

          let seenAlready = []
          if (
            nodesTreeDepsCircularCheck(
              sliceDraft.dates[dateStatusCurrent].nodes,
              payload.nId,
              seenAlready
            )
          ) {
            sliceDraft.messageFromProject =
              "ERROR: You have used a circular reference, but this is not possible."
            sliceDraft.dates[dateStatusCurrent].nodes = nodesTemp
          } else {
            // clean redundant dependencies starting from parent of nId
            // sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDepsClean(
            //   sliceDraft.dates[dateStatusCurrent].nodes,
            //   ROOT
            // )

            sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
              sliceDraft.dates[dateStatusCurrent].nodes,
              ROOT,
              dateStatusCurrent
            )

            // let nodesInUpdate1 = cloneDeep(
            //   sliceDraft.dates[dateStatusCurrent].nodes
            // )

            // let nodesOutUpdate1 = nodesTreeDownUp(
            //   nodesInUpdate1,
            //   ROOT,
            //   dateStatusCurrent
            // )

            // sliceDraft.dates[
            //   dateStatusCurrent
            // ].nodes = nodesFromByWhenPipeWrapper(
            //   sliceDraft.dates[dateStatusCurrent].nodes,
            //   payload.nId
            // )

            // // clean redundant dependencies starting from parent of nId
            // sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDepsClean(
            //   sliceDraft.dates[dateStatusCurrent].nodes,
            //   sliceDraft.dates[dateStatusCurrent].nodes[payload.nId].pId
            // )

            // sliceDraft.dates[dateStatusCurrent].nodes = cleanDependentsAll(
            //   sliceDraft.dates[dateStatusCurrent].nodes
            // )

            // console.log(
            //   "rowUpdate 1 different? ",
            //   deepDiff(
            //     { ...sliceDraft.dates[dateStatusCurrent].nodes },
            //     nodesOutUpdate1
            //   ),
            //   { nodesOutUpdate1 }
            // )
          }
        }

        // update by-when if not before project dateStart
        if (payload.data.byWhen) {
          // project dateStart
          const dateStartDate = parseISO(
            sliceDraft.dates[dateStatusCurrent].nodes[ROOT].fromWhen
          )

          // suggested new byWhen
          const byWhenDate = parseISO(
            locToISO(payload.data.byWhen, dateStatusCurrent)
          )

          // byWhen cannot be before the project dateStart
          if (isBefore(byWhenDate, dateStartDate)) {
            sliceDraft.messageFromProject =
              "ERROR: BY WHEN dates cannot be before the project Start Date. You can change the project Start Date on the Setup page."
            const dateStartDayEndDate = endOfBusinessDay(
              parseISO(sliceDraft.dates[dateStatusCurrent].nodes[ROOT].fromWhen)
            )
            sliceDraft.dates[dateStatusCurrent].nodes[
              payload.nId
            ].byWhen = dateStartDayEndDate.toISOString()
          } else {
            sliceDraft.dates[dateStatusCurrent].nodes[
              payload.nId
            ].byWhen = byWhenDate.toISOString()

            if (
              sliceDraft.dates[dateStatusCurrent].nodes[payload.nId]
                .isByWhenPinned
            ) {
              sliceDraft.dates[dateStatusCurrent].nodes[
                payload.nId
              ].byWhenLatest =
                sliceDraft.dates[dateStatusCurrent].nodes[payload.nId].byWhen
            }

            sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
              sliceDraft.dates[dateStatusCurrent].nodes,
              ROOT,
              dateStatusCurrent
            )

            // // vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
            // let nodesInUpdate2 = cloneDeep(
            //   sliceDraft.dates[dateStatusCurrent].nodes
            // )
            // let nodesOutUpdate2 = nodesTreeDownUp(
            //   nodesInUpdate2,
            //   ROOT,
            //   dateStatusCurrent
            // )

            // // re-calculates the byWhenFct as a result of a changed byWhen
            // sliceDraft.dates[dateStatusCurrent].nodes = byWhenFctSet(
            //   sliceDraft.dates[dateStatusCurrent].nodes,
            //   payload.nId
            // )

            // sliceDraft.dates[
            //   dateStatusCurrent
            // ].nodes = nodesFromByWhenPipeWrapper(
            //   sliceDraft.dates[dateStatusCurrent].nodes,
            //   payload.nId
            // )

            // sliceDraft.dates[
            //   dateStatusCurrent
            // ].nodes = nodesByWhenAccumulateDependencyRestriction(
            //   sliceDraft.dates[dateStatusCurrent].nodes
            // )

            // sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeSpanSlackPipe(
            //   sliceDraft.dates[dateStatusCurrent].nodes
            // )

            // console.log(
            //   "rowUpdate 2 different? ",
            //   deepDiff(
            //     { ...sliceDraft.dates[dateStatusCurrent].nodes },
            //     nodesOutUpdate2
            //   ),
            //   { nodesOutUpdate2 }
            // )
            // //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

            sliceDraft.isResetForm = true
          }
        }

        // accumulate spent only if change in spent
        if (payload.data.spent) {
          sliceDraft.dates[dateStatusCurrent].nodes[payload.nId] = {
            ...sliceDraft.dates[dateStatusCurrent].nodes[payload.nId],
            spent: durStrToDur(payload.data.spent),
          }
        }

        sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.dates[dateStatusCurrent].nodes,
          ROOT,
          dateStatusCurrent
        )

        // let nodesInUpdate3 = cloneDeep(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )
        // let nodesOutUpdate3 = nodesTreeDownUp(
        //   nodesInUpdate3,
        //   ROOT,
        //   dateStatusCurrent
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = completionAccPipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesDeadlinePipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes,
        //   payload.nId,
        //   dateStatusCurrent
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesStatusAcc(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // console.log(
        //   "rowUpdate 3 different? ",
        //   deepDiff(
        //     { ...sliceDraft.dates[dateStatusCurrent].nodes },
        //     nodesOutUpdate3
        //   ),
        //   { nodesOutUpdate3 }
        // )

        sliceDraft.isResetForm = true
      })
      return sliceNext

    case rowIgnore.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].nodes[
          payload.nId
        ].isIgnored = !sliceDraft.dates[dateStatusCurrent].nodes[payload.nId]
          .isIgnored

        sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.dates[dateStatusCurrent].nodes,
          ROOT,
          dateStatusCurrent
        )

        // let nodesInIgnore = cloneDeep(sliceDraft.dates[dateStatusCurrent].nodes)
        // let nodesOutIgnore = nodesTreeDownUp(
        //   nodesInIgnore,
        //   ROOT,
        //   dateStatusCurrent
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = completionAccPipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesDeadlinePipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes,
        //   ROOT,
        //   dateStatusCurrent
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesStatusAcc(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // console.log(
        //   "rowIgnore different? ",
        //   deepDiff(
        //     { ...sliceDraft.dates[dateStatusCurrent].nodes },
        //     nodesOutIgnore
        //   ),
        //   { nodesOutIgnore }
        // )
      })
      return sliceNext

    case rowUnresolved.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].nodes[
          payload.nId
        ].isUnresolved = !sliceDraft.dates[dateStatusCurrent].nodes[payload.nId]
          .isUnresolved

        if (
          sliceDraft.dates[dateStatusCurrent].nodes[payload.nId].isUnresolved
        ) {
          sliceDraft.dates[dateStatusCurrent].ratios.unresolvedIssues += 1
        } else {
          sliceDraft.dates[dateStatusCurrent].ratios.unresolvedIssues -= 1
        }

        sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.dates[dateStatusCurrent].nodes,
          ROOT,
          dateStatusCurrent
        )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesStatusAcc(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = completionAccPipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )
      })
      return sliceNext

    case rowMoveIn.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].nodes = moveIn(
          sliceDraft.dates[dateStatusCurrent].nodes,
          payload.nId,
          sliceDraft.dates[dateStatusCurrent].rows
        )

        sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.dates[dateStatusCurrent].nodes,
          ROOT,
          dateStatusCurrent
        )

        // let nodesInMoveIn = cloneDeep(sliceDraft.dates[dateStatusCurrent].nodes)
        // let nodesOutMoveIn = nodesTreeDownUp(
        //   nodesInMoveIn,
        //   ROOT,
        //   dateStatusCurrent
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDepsClean(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesPositionsGen(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesFromByWhenPipeWrapper(
        //   sliceDraft.dates[dateStatusCurrent].nodes,
        //   ROOT
        // )

        // sliceDraft.dates[
        //   dateStatusCurrent
        // ].nodes = nodesByWhenAccumulateDependencyRestriction(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = completionAccPipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesDeadlinePipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes,
        //   ROOT,
        //   dateStatusCurrent
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesStatusAcc(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // console.log(
        //   "rowMoveIn different? ",
        //   deepDiff(
        //     { ...sliceDraft.dates[dateStatusCurrent].nodes },
        //     nodesOutMoveIn
        //   ),
        //   { nodesOutMoveIn }
        // )

        sliceDraft.dates[dateStatusCurrent].rows = nodesRowsGen(
          sliceDraft.dates[dateStatusCurrent].nodes
        )
      })
      return sliceNext

    case rowMoveOut.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].nodes = moveOut(
          sliceDraft.dates[dateStatusCurrent].nodes,
          payload.nId
        )

        sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.dates[dateStatusCurrent].nodes,
          ROOT,
          dateStatusCurrent
        )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesPositionsGen(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesFromByWhenPipeWrapper(
        //   sliceDraft.dates[dateStatusCurrent].nodes,
        //   ROOT
        // )

        // // parallel to rowDelete
        // // needed, because potentially a pinned dependent could have been deleted.
        // // In that case byWhen and byWhenLatest have to be re-calculated
        // sliceDraft.dates[
        //   dateStatusCurrent
        // ].nodes = nodesByWhenAccumulateDependencyRestriction(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // // needed because span and slack change in _root
        // sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeSpanSlackPipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = completionAccPipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // // needed because deadline traffic light could change because of deletion
        // sliceDraft.dates[dateStatusCurrent].nodes = nodesDeadlinePipe(
        //   sliceDraft.dates[dateStatusCurrent].nodes,
        //   ROOT,
        //   dateStatusCurrent
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesStatusAcc(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        sliceDraft.dates[dateStatusCurrent].rows = nodesRowsGen(
          sliceDraft.dates[dateStatusCurrent].nodes
        )
      })
      return sliceNext

    case rowMoveUpDown.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].nodes = moveUpDown(
          sliceDraft.dates[dateStatusCurrent].nodes,
          payload.nId,
          sliceDraft.dates[dateStatusCurrent].rows,
          payload.isMoveUp
        )

        sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.dates[dateStatusCurrent].nodes,
          ROOT,
          dateStatusCurrent,
          {
            doPositions: true,
            doDependenciesClean: false,
            doFromByWhens: false,
            doTrafficLights: false,
            doEarliestLatest: false,
          }
        )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesPositionsGen(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        sliceDraft.dates[dateStatusCurrent].rows = nodesRowsGen(
          sliceDraft.dates[dateStatusCurrent].nodes
        )
      })
      return sliceNext

    case rowFold.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].nodes[
          payload.nId
        ].isFolded = !sliceDraft.dates[dateStatusCurrent].nodes[payload.nId]
          .isFolded

        sliceDraft.dates[dateStatusCurrent].rows = nodesRowsGen(
          sliceDraft.dates[dateStatusCurrent].nodes
        )
      })
      return sliceNext

    case rowForecast.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].nodes[payload.nId].forecast =
          (sliceDraft.dates[dateStatusCurrent].nodes[payload.nId].forecast +
            1) %
          5

        sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.dates[dateStatusCurrent].nodes,
          ROOT,
          dateStatusCurrent,
          {
            doTrafficLights: true,
            doPositions: false,
            doDependenciesClean: false,
            doFromByWhens: false,
            doEarliestLatest: false,
          }
        )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesStatusAcc(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )
      })
      return sliceNext

    case rowQuality.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].nodes[payload.nId].quality =
          (sliceDraft.dates[dateStatusCurrent].nodes[payload.nId].quality + 1) %
          5

        sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.dates[dateStatusCurrent].nodes,
          ROOT,
          dateStatusCurrent,
          {
            doTrafficLights: true,
            doPositions: false,
            doDependenciesClean: false,
            doFromByWhens: false,
            doEarliestLatest: false,
          }
        )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesStatusAcc(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )
      })
      return sliceNext

    // PIN -------------------------------------------------------
    case isByWhenPinnedUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].nodes[
          payload.nId
        ].isByWhenPinned = !sliceDraft.dates[dateStatusCurrent].nodes[
          payload.nId
        ].isByWhenPinned

        sliceDraft.dates[dateStatusCurrent].nodes[payload.nId].byWhenLatest =
          sliceDraft.dates[dateStatusCurrent].nodes[payload.nId].byWhen

        sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.dates[dateStatusCurrent].nodes,
          ROOT,
          dateStatusCurrent,
          {
            doPositions: false,
            doDependenciesClean: false,
            doFromByWhens: true,
            doEarliestLatest: true,
            doTrafficLights: true,
          }
        )

        // let nodesInPinned = cloneDeep(sliceDraft.dates[dateStatusCurrent].nodes)
        // let nodesOutPinned = nodesTreeDownUp(
        //   nodesInPinned,
        //   ROOT,
        //   dateStatusCurrent
        // )

        // sliceDraft.dates[dateStatusCurrent].nodes = byWhenFctSet(
        //   sliceDraft.dates[dateStatusCurrent].nodes,
        //   payload.nId
        // )

        // sliceDraft.dates[
        //   dateStatusCurrent
        // ].nodes = nodesByWhenAccumulateDependencyRestriction(
        //   sliceDraft.dates[dateStatusCurrent].nodes
        // )

        // console.log(
        //   "isByWhenPinnedUpdate different? ",
        //   deepDiff(
        //     { ...sliceDraft.dates[dateStatusCurrent].nodes },
        //     nodesOutPinned
        //   )
        // )
      })

      return sliceNext

    case isHighlightedUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].nodes[
          payload.nId
        ].isHighlighted = !sliceDraft.dates[dateStatusCurrent].nodes[
          payload.nId
        ].isHighlighted
      })

      return sliceNext

    // SETTINGS -------------------------------------------------------
    case isDetailsOnUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.params.isDetailsOn = !sliceDraft.params.isDetailsOn
      })
      return sliceNext

    case isNameFirstPreferredUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.params.isNameFirstPreferred = !sliceDraft.params
          .isNameFirstPreferred
        Object.keys(sliceDraft.dates).forEach(
          (dId) =>
            (sliceDraft.dates[dId].persons = personsDisplayNameGen(
              sliceDraft.dates[dId].persons,
              sliceDraft.params.isNameFirstPreferred
            ))
        )
      })
      return sliceNext

    // FORMS -------------------------------------------------------
    case formSetupUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].setup = {
          ...sliceDraft.dates[dateStatusCurrent].setup,
          ...payload.data,
        }
      })
      return sliceNext

    case selectStatusUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].setup.statusCompleted =
          payload.statusCompleted
      })
      return sliceNext

    case formDatesUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        // one can only set a new dateStatusNext when the dateStatusCurrent is set isCompleted
        if (
          sliceDraft.dates[dateStatusCurrent].setup.statusCompleted ===
            "Is Completed" &&
          payload.data.dateStatusNext !== ""
        ) {
          let dateStatusNextDate = startOfBusinessDay(
            parseISO(locToISO(payload.data.dateStatusNext, dateStatusCurrent))
          )

          if (isWeekend(dateStatusNextDate)) {
            dateStatusNextDate = addBusinessDays(dateStatusNextDate, 1)
          }

          // must be at least two days after last status day
          let dateStatusCurrentPlus1 = addBusinessDays(
            parseISO(dateStatusCurrent),
            1
          )

          if (
            !isSameDay(dateStatusNextDate, dateStatusCurrentPlus1) &&
            !isAfter(dateStatusNextDate, dateStatusCurrentPlus1)
          ) {
            sliceDraft.messageFromProject =
              "ERROR: Next status date must be at least one day after previous one."
          }
          // new Status Date accepted
          else {
            payload.data.dateStatusNext = dateStatusNextDate.toISOString()

            sliceDraft.dates[payload.data.dateStatusNext] = cloneDeep(
              sliceDraft.dates[dateStatusCurrent]
            )

            sliceDraft.dates[dateStatusCurrent].setup.isStatusLocked = true
            sliceDraft.datesStatusList.unshift(payload.data.dateStatusNext)

            sliceDraft.dates[payload.data.dateStatusNext].dId =
              payload.data.dateStatusNext

            // from Current to Next
            sliceDraft.dateStatusCurrent = payload.data.dateStatusNext
            dateStatusCurrent = payload.data.dateStatusNext
            sliceDraft.dates[dateStatusCurrent].setup.isStatusLocked = false

            sliceDraft.dateStatusNext = ""
            sliceDraft.dates[dateStatusCurrent].setup.statusCompleted =
              "In Progress"

            sliceDraft.messageFromProject = `Success: Current Status Date was changed to ${format(
              parseISO(dateStatusCurrent),
              "yyyy-MM-dd"
            )}.`

            // re-calc
            sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
              sliceDraft.dates[dateStatusCurrent].nodes,
              ROOT,
              dateStatusCurrent,
              {
                doPositions: false,
                doDependenciesClean: false,
                doFromByWhens: true,
                doEarliestLatest: true,
                doTrafficLights: true,
              }
            )

            // sliceDraft.dates[dateStatusCurrent].nodes = nodesDeadlinePipe(
            //   sliceDraft.dates[dateStatusCurrent].nodes,
            //   ROOT,
            //   dateStatusCurrent
            // )

            // sliceDraft.dates[dateStatusCurrent].nodes = nodesStatusAcc(
            //   sliceDraft.dates[dateStatusCurrent].nodes
            // )
          }
        }

        // the remaining input fields in the form

        // check if dateStart is on a weekend
        let dateStartDate = parseISO(
          locToISO(
            payload.data.dateStart,
            dateStatusCurrent,
            true // ← startOfBusinessDay
          )
        )
        if (isWeekend(dateStartDate)) {
          dateStartDate = addBusinessDays(dateStartDate, 1)
        }

        sliceDraft.dates[dateStatusCurrent].nodes[
          ROOT
        ].fromWhen = dateStartDate.toISOString()

        if (payload.data.dateStatusNextPlanned !== "") {
          // check if dateStatusNextPlanned is on a weekend
          let dateStatusNextPlannedDate = startOfDay(
            parseISO(
              locToISO(payload.data.dateStatusNextPlanned, dateStatusCurrent)
            )
          )
          if (isWeekend(dateStatusNextPlannedDate)) {
            dateStatusNextPlannedDate = addBusinessDays(
              dateStatusNextPlannedDate,
              1
            )
          }

          sliceDraft.dates[
            dateStatusCurrent
          ].dateStatusNextPlanned = dateStatusNextPlannedDate.toISOString()
        }

        sliceDraft.dates[dateStatusCurrent].nodes = nodesTreeDownUp(
          sliceDraft.dates[dateStatusCurrent].nodes,
          ROOT,
          dateStatusCurrent,
          {
            doPositions: false,
            doDependenciesClean: false,
            doFromByWhens: true,
            doEarliestLatest: true,
            doTrafficLights: true,
          }
        )

        // sliceDraft.dates[dateStatusCurrent].nodes = nodesFromByWhenPipeWrapper(
        //   sliceDraft.dates[dateStatusCurrent].nodes,
        //   ROOT
        // )

        if (payload.data.dayStart !== sliceDraft.params.dayStart) {
          sliceDraft.messageFromProject =
            "Warning: Day Start h and Day End h cannot be changed in this version."
        }
        if (payload.data.dayEnd !== sliceDraft.params.dayEnd) {
          sliceDraft.messageFromProject =
            "Warning: Day Start h and Day End h cannot be changed in this version."
        }
        // sliceDraft.params.dayStart = payload.data.dayStart
        // sliceDraft.params.dayEnd = payload.data.dayEnd
        sliceDraft.isResetForm = true
      })

      return sliceNext

    case formRatiosUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].ratios = {
          ...sliceDraft.dates[dateStatusCurrent].ratios,
          ...payload.data,
        }
      })
      return sliceNext

    case issueCheckUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.dates[dateStatusCurrent].ratios[payload.name] = !sliceDraft
          .dates[dateStatusCurrent].ratios[payload.name]
      })
      return sliceNext

    case issueTLUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        // TODO: result from API1 → API2. Can be removed at v1.0.0.
        if (
          sliceDraft.dates[dateStatusCurrent].ratios[payload.name] === undefined
        ) {
          sliceDraft.dates[dateStatusCurrent].ratios[payload.name] = 1
        } else {
          sliceDraft.dates[dateStatusCurrent].ratios[payload.name] =
            (sliceDraft.dates[dateStatusCurrent].ratios[payload.name] + 1) % 5
        }
      })
      return sliceNext

    case formParamsUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.params.pUnresolvedIssues = payload.data.pUnresolvedIssues
          .split(",")
          .map((el) => parseInt(el))
        sliceDraft.params.pDOCThroughDOXS = payload.data.pDOCThroughDOXS
          .split(",")
          .map((el) => parseInt(el))
        sliceDraft.params.pDOCThroughDOTS = payload.data.pDOCThroughDOTS
          .split(",")
          .map((el) => parseInt(el))
        sliceDraft.params.pSatisfactionCustomer = payload.data.pSatisfactionCustomer
          .split(",")
          .map((el) => parseInt(el))
        sliceDraft.params.pSatisfactionTeam = payload.data.pSatisfactionTeam
          .split(",")
          .map((el) => parseInt(el))
      })
      return sliceNext

    // MESSAGE -------------------------------------------------------
    case messageFromProjectDelete.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.messageFromProjectDelete = ""
        sliceDraft.isResetForm = true
      })
      return sliceNext

    // DEFAULT -------------------------------------------------------
    default:
      return sliceNext
  }
}

export default project
