import React from "react"
import { chakra, Flex } from "@chakra-ui/react"
import GanttBox from "../GanttBox"
import parseISO from "date-fns/parseISO"
import isAfter from "date-fns/isAfter"
import isBefore from "date-fns/isBefore"
import endOfDay from "date-fns/endOfDay"
import differenceInHours from "date-fns/differenceInHours"
import posToStr from "../../functions/utils/posToStr"
import { levelColorSet } from "../../functions/helpers/levelColorSet"
import {
  startOfBusinessDay,
  endOfBusinessDay,
} from "../../functions/timeHandler/bordersOfBusinessDay"

import { isHighlightedUpdate } from "../../redux/actions/project"

import { ROOT, DAY_START, DAY_END, HOURS_PER_DAY } from "../../const/globals"

const GanttBar = ({ node, boxW, timeLine, ganttDurationIndex, dispatch }) => {
  //=============================================================

  const onclickHighlighted = () => {
    const payload = { nId }
    dispatch(isHighlightedUpdate(payload))
  }

  //=============================================================

  const {
    nId,
    fromWhen,
    byWhen,
    toProjectionISO,
    fromSlackISO,
    slackStr,
    levelMax,
    isIgnored,
    position,
    children,
    isByWhenPinned,
    deliversWhat,
    isHighlighted,
  } = node

  const level = position.length
  const hasChildren = children.length > 0 || nId === ROOT

  //--------------------------------------------------------------

  const barStart = parseISO(fromWhen)
  const barEnd = parseISO(byWhen)
  const projectionEnd = parseISO(toProjectionISO)
  const slackStart = parseISO(fromSlackISO)

  const isViewWeek = ganttDurationIndex === 1

  const wCalc = ({ dayStart, dayEnd }) => {
    let diff0, diff1, diff2, diff3

    if (isAfter(barStart, dayStart)) {
      diff0 = differenceInHours(barStart, dayStart)
      diff1 = differenceInHours(projectionEnd, barStart)
    } else {
      diff0 = 0
      diff1 = differenceInHours(projectionEnd, dayStart)
    }

    if (isBefore(barEnd, dayEnd)) {
      diff2 = differenceInHours(barEnd, slackStart)
      diff3 = differenceInHours(dayEnd, barEnd)
    } else {
      diff2 = differenceInHours(dayEnd, slackStart)
      diff3 = 0
    }

    let w0 = diff0 < 0 || diff0 > HOURS_PER_DAY ? 0 : diff0 / HOURS_PER_DAY
    let w1 = diff1 < 0 || diff1 > HOURS_PER_DAY ? 0 : diff1 / HOURS_PER_DAY
    let w2 = diff2 < 0 || diff2 > HOURS_PER_DAY ? 0 : diff2 / HOURS_PER_DAY
    let w3 = diff3 < 0 || diff3 > HOURS_PER_DAY ? 0 : diff3 / HOURS_PER_DAY
    let w = { w0, w1, w2, w3 }
    return w
  }

  const renderBar = () => {
    let renderedBar = Array(timeLine.length)
      .fill(null)
      .map((value, index) => {
        const dateStart = parseISO(timeLine[index].date)
        const dateEnd = endOfDay(parseISO(timeLine[index].date))
        const dayStart = startOfBusinessDay(dateStart, DAY_START)
        const dayEnd = endOfBusinessDay(dateStart, DAY_END)
        const isBar = isAfter(dateEnd, barStart) && isBefore(dateStart, barEnd)
        const isAfterBarEnd = isAfter(dayStart, barEnd)
        const isWeekend = timeLine[index].isWeekend
        const isProjection = isBefore(dateEnd, slackStart)
        const isFade =
          isBefore(dateStart, projectionEnd) && isAfter(dateEnd, slackStart)
        const isPartialStart =
          isAfter(barStart, dayStart) && isBefore(barStart, dayEnd)
        const isPartialEnd =
          isAfter(barEnd, dayStart) && isBefore(barEnd, dayEnd)
        const isFirst =
          isAfter(barStart, dateStart) && isBefore(barStart, dateEnd)
        const isLast = isAfter(barEnd, dateStart) && isBefore(barEnd, dateEnd)
        const isFriday = timeLine[index].dayOfWeek === 5

        // 1 GANTT DURATION IS WEEK =================================================
        if (isViewWeek) {
          // 1.1 No Weekend (do nothing if weekend) ---------------------------------
          if (!isWeekend) {
            // 1.1.1 Is Projection --------
            if (!isLast) {
              return (
                <GanttBox
                  key={index}
                  isViewWeek={true}
                  boxW={boxW}
                  hoursPerDay={HOURS_PER_DAY}
                  isBar={isBar}
                  isAfterBarEnd={isAfterBarEnd}
                  isPartialStart={isPartialStart}
                  hasChildren={hasChildren}
                  isProjection={isProjection}
                  isFade={isFade}
                  isFriday={isFriday}
                  {...levelColorSet({
                    prop: "bg",
                    level,
                    levelMax,
                    isIgnored,
                  })}
                />
              )
            }
            // 1.1.2 Is Last (show Deliverable) -------------
            else {
              return (
                <Flex
                  key={index}
                  fontFamily="Inconsolata"
                  fontSize={boxW + "px"}
                  align="center"
                >
                  <GanttBox
                    key={index}
                    boxW={boxW}
                    hoursPerDay={HOURS_PER_DAY}
                    isBar={isBar}
                    isByWhenPinned={isByWhenPinned}
                    isViewWeek={true}
                    isFriday={isFriday}
                    hasChildren={hasChildren}
                    isProjection={isProjection}
                    isFade={isFade}
                    isPartialEnd={isPartialEnd}
                    {...levelColorSet({
                      prop: "bg",
                      level,
                      levelMax,
                      isIgnored,
                    })}
                  />
                  <chakra.button
                    type="button"
                    onClick={onclickHighlighted}
                    textDecoration={isHighlighted ? "underline double" : "none"}
                    textAlign="left"
                    outline="none"
                    flex="0 0 auto"
                    pl={1}
                  >
                    {deliversWhat}
                  </chakra.button>
                </Flex>
              )
            }
          }
          // 1.2 Weekend (return nothing) ----------------------------------
          else {
            return null
          }
        }
        // 2. GANTT DURATION IS DAY ===============================================
        else {
          // 2.1 Is Weekend --------------------
          if (isWeekend) {
            return (
              <GanttBox
                key={index}
                boxW={boxW}
                isBar={isBar}
                isAfterBarEnd={isAfterBarEnd}
                w1={1}
                bg="grey.300"
              />
            )
            // 2.2 Not is Weekend --------------------
          } else {
            // 2.2.1 Is Last (shows Pins and Deliverables) ----
            if (isLast) {
              return (
                <Flex
                  key={index}
                  fontFamily="Inconsolata"
                  fontSize={boxW + "px"}
                  align="center"
                >
                  <GanttBox
                    key={index}
                    {...wCalc({ dayStart, dayEnd })}
                    boxW={boxW}
                    hoursPerDay={HOURS_PER_DAY}
                    isBar={isBar}
                    hasChildren={hasChildren}
                    isProjection={isProjection}
                    isFade={isFade}
                    isByWhenPinned={isByWhenPinned}
                    {...levelColorSet({
                      prop: "bg",
                      level,
                      levelMax,
                      isIgnored,
                    })}
                  />
                  <chakra.button
                    type="button"
                    onClick={onclickHighlighted}
                    textDecoration={isHighlighted ? "underline double" : "none"}
                    textAlign="left"
                    outline="none"
                    flex="0 0 auto"
                    pl={1}
                  >
                    {deliversWhat}
                  </chakra.button>
                </Flex>
              )
            }
            // 2.2.2 Is First (may have partial GanttBox) ----
            else if (isFirst) {
              return (
                <GanttBox
                  key={index}
                  {...wCalc({ dayStart, dayEnd })}
                  boxW={boxW}
                  hoursPerDay={HOURS_PER_DAY}
                  isBar={isBar}
                  hasChildren={hasChildren}
                  isProjection={isProjection}
                  isFade={isFade}
                  {...levelColorSet({
                    prop: "bg",
                    level,
                    levelMax,
                    isIgnored,
                  })}
                />
              )
            }
            // 2.2.3 Neither First nor Last (shows full GanttBox) ----
            else {
              return (
                <GanttBox
                  key={index}
                  w1={1}
                  boxW={boxW}
                  hoursPerDay={HOURS_PER_DAY}
                  isBar={isBar}
                  isAfterBarEnd={isAfterBarEnd}
                  hasChildren={hasChildren}
                  isProjection={isProjection}
                  isFade={isFade}
                  {...levelColorSet({
                    prop: "bg",
                    level,
                    levelMax,
                    isIgnored,
                  })}
                />
              )
            }
          }
        }
      })

    return renderedBar
  }

  //=============================================================
  let colorProp = {
    ...levelColorSet({
      prop: "color",
      level,
      levelMax,
      isIgnored,
    }),
  }

  if (!hasChildren & (slackStr[0] === "-")) {
    colorProp.color = "pink.500"
  }

  return (
    <Flex mb={0} align="center">
      <chakra.button
        type="button"
        onClick={onclickHighlighted}
        textAlign="left"
        textDecoration={isHighlighted ? "underline double" : "none"}
        outline="none"
        minW="160px"
        w="160px"
        fontFamily="mono"
        fontSize={boxW + "px"}
        flex="0 0"
        my={0}
        {...colorProp}
      >
        {posToStr(position) === "" ? "TOTAL" : posToStr(position)}
      </chakra.button>
      {renderBar()}
    </Flex>
  )
}

export default GanttBar
