import React from "react"
import { connect } from "react-redux"
import CreatableSelect from "react-select/creatable"
import CustomClearIndicator from "./CustomClearIndicator"
import customStyles from "./style/customStyles"
import { SelectTodosUpdate } from "../../redux/actions/project"

const SelectTodos = ({
  name,
  value,
  optionsTodos,
  isDisabled = false,
  isLocked = false,
  dispatch,
}) => {
  //=================================================================
  const onChange = (data, actionMeta) => {
    // data.label has either the rId or - if new - the new name
    // select Id = sId has nId here

    let payload = {}

    if (actionMeta.action === "clear") {
      payload = { name, selected: [], isNew: false }
    } else {
      payload = { name, selected: data }
    }

    dispatch(SelectTodosUpdate(payload))
  }
  //=================================================================
  return (
    <CreatableSelect
      name="todos"
      value={value}
      onChange={onChange}
      options={optionsTodos}
      styles={customStyles}
      isMulti
      isDisabled={isLocked}
      isLocked={isLocked}
      className="basic-multi-select"
      classNamePrefix="select"
      components={{
        ClearIndicator: CustomClearIndicator,
        DropdownIndicator: null,
        IndicatorSeparator: null,
        Placeholder: () => null,
      }}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    // TODO: was introduced from API1 → API2
    // remove when API has more matured
    optionsTodos: state.project.dates[state.project.dateStatusCurrent]
      .todosAdded
      ? [
          ...state.project.todosTypical,
          ...state.project.dates[state.project.dateStatusCurrent].todosAdded,
        ]
      : state.project.todosTypical,
  }
}

export default connect(mapStateToProps)(SelectTodos)
