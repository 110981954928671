import { copyDeep } from "../utils/objectCopyDeep"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generate the displayName for all persons in the persons object.
 * It returns a NEW object.
 * @param {object} persons - object of persons
 * @param {boolean} isNameFirstPreferred
 * @return {object} personsNew
 */
export function personsDisplayNameGen(persons, isNameFirstPreferred) {
  let personsNew = copyDeep(persons)

  Object.keys(personsNew).forEach((rId) => {
    personDisplayNameGen(personsNew[rId], isNameFirstPreferred)
  })
  return personsNew
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generate the displayName of a person object from existing names
 * and return the SAME object
 * @param {object} persons - object of persons
 * @param {boolean} isNameFirstPreferred
 * @return {object} person
 */
export function personDisplayNameGen(person, isNameFirstPreferred) {
  if (person.nameFirst === "" && person.nameLast === "") {
    person.nameDisplayShort = ""
    person.nameDisplayLong = ""
  } else if (person.nameFirst === "" || person.nameLast === "") {
    person.nameDisplayShort = person.nameFirst + person.nameLast
    person.nameDisplayLong = person.nameFirst + person.nameLast
  } else if (isNameFirstPreferred) {
    person.nameDisplayShort = person.nameFirst + " " + person.nameLast[0]
    person.nameDisplayLong = person.nameFirst + " " + person.nameLast
  } else {
    person.nameDisplayShort = person.nameLast + " " + person.nameFirst[0]
    person.nameDisplayLong = person.nameLast + " " + person.nameFirst
  }

  return person
}
