// https:regexr.com/

export const NUMBER_MESSAGE = "Invalid: Please enter a positive integer number."
export const NUMBER_REGEX = /^[0-9]\d*$/i

export const NAME_MESSAGE = "Invalid: Please enter a least on character."
export const NAME_REGEX = /^[ a-zA-Z\xC0-\uFFFF]*$/i

export const EMAIL_MESSAGE = "Invalid: This is not a valid email."
export const EMAIL_REGEX = /^\S+@\S+\.\S+$/i

export const DATE_MESSAGE =
  "Invalid: Please enter a date in the form yyyy-MM-dd."
export const DATE_REGEX = /^^((20[2-9][0-9])-(0[1-9]|1[0-2])-([0-2][0-9]|3[0-1]))|((0[1-9]|1[0-2])-([0-2][0-9]|3[0-1]))$/i

export const ABBR_MESSAGE =
  "Invalid: Please enter a date in the form yyyy-MM-dd|HH or a substring of it."

export const ABBR_REGEX = /^\d+((-|:)\d+)*(-|:)?$/i

export const ABBR_D_MESSAGE =
  "Invalid: Please enter a date in the form yyyy-MM-dd or a substring of it."

export const ABBR_D_REGEX = /^\d+((-)\d+)*(-)?$/i

export const ABBR_H_MESSAGE =
  "Invalid: Please enter a an hour in the form H or HH."

export const ABBR_H_REGEX = /^[0-9]|[0-2][0-9]$/i

// more exact, but it is to restrictive
export const ABBR_REGEX2 = /^(20[2-9][0-9]-((0[1-9])|(1[0-2]))-([0-2][1-9]|3[0-1])(:[0-2][1-9])?)|(((0[1-9])|(1[0-2]))-([0-2][1-9]|3[0-1])(:[0-2][1-9])?)|(([0-2][1-9]|3[0-1])(:[0-2][1-9])?)$/i

export const PERCENT_MESSAGE =
  "Invalid: Please enter a percentage number within 0–100."
export const PERCENT_REGEX = /^(100|[1-9][0-9]|[0-9])$/i

export const DURATION_MESSAGE =
  "Invalid: Please enter a duration in the form of 1y2m3w4d5 or a substring of it."
export const DURATION_REGEX = /^(\d*y)?(\d*m)?(\d*w)?(\d*d)?\d*h?$/i

export const POSITION_MESSAGE =
  "Invalid: Please enter a position in the form of 01.02.03 with or without leading zeros."
export const POSITION_REGEX = /^(\d\d?\.?)*$/i

export const DAY_START_MESSAGE =
  "Invalid: Please enter one of the following day start hours: 6, 7, 8, 9, 10."
export const DAY_START_REGEX = /^[6-9]|10$/i
export const DAY_END_MESSAGE =
  "Invalid: Please enter one of the following day end hours: 15, 16, 17, 18, 19, 20."
export const DAY_END_REGEX = /^[1][5-9]|20$/i

export const PARAMS_BORDERS_MESSAGE =
  "Invalid: Please enter the parameter borders in this form: 50, 70, 80, 90."
export const PARAMS_BORDERS_REGEX = /^([1-9][0-9]|[0-9]),\s?([1-9][0-9]|[0-9]),\s?([1-9][0-9]|[0-9]),\s?([1-9][0-9][0-9]|[1-9][0-9]|[0-9])$/i
