import { copyDeep } from "../utils/objectCopyDeep"
import { ROOT } from "../../const/globals"

/**
 * (c) Ulrich Anders
 *
 * Cleans all nodes in the tree from redundant precedents and dependents:
 * - remove circular dependencies???
 * - parents, that are precedents of their children
 * - precedents in children that are already in the parent
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodesNew
 */
export function nodesTreeDepsClean(nodes, nId = ROOT) {
  let nodesNew = copyDeep(nodes)

  let nodeNew = nodesNew[nId]
  let { children, precedents } = nodeNew

  if (children.length > 0) {
    children.forEach((child) => {
      // if nId is precedent of its child
      if (
        nodesNew[child].precedents.length > 0 &&
        nodesNew[child].precedents.indexOf(nId) > -1
      ) {
        // filter out nId from child.precedents
        nodesNew[child].precedents = nodesNew[child].precedents.filter(
          (childPrecedent) => childPrecedent !== nId
        )
        // filter out child from nId.dependents
        nodesNew[nId].dependents = nodesNew[nId].dependents.filter(
          (dependentOfNId) => dependentOfNId !== nId
        )
      }
    })

    precedents.forEach((precedent) => {
      children.forEach((child) => {
        // remove node.precedent from child.precedent
        if (
          nodesNew[child].precedents.length > 0 &&
          nodesNew[child].precedents.indexOf(precedent) > -1
        ) {
          // filter out precedent from child.precedents
          nodesNew[child].precedents = nodesNew[child].precedents.filter(
            (childPrecedent) => childPrecedent !== precedent
          )

          // filter out child from precedent.dependents
          nodesNew[precedent].dependents = nodesNew[
            precedent
          ].dependents.filter(
            (dependentOfPrecedent) =>
              dependentOfPrecedent !== nodesNew[child].nId
          )
        }
      })
    })

    children.forEach((child) => {
      nodesNew = nodesTreeDepsClean(nodesNew, child)
    })
  }

  return nodesNew
}

/**
 * (c) Ulrich Anders
 *
 * @param {*} nodes
 * @param {*} nId
 * @returns {}
 */
export function nodesTreeDepsCircularCheck(
  nodes,
  nId = ROOT,
  seenAlready = []
) {
  let isCircular = false

  // console.log({
  //   nId,
  //   seenAlready: [...seenAlready].join(", "),
  //   precedents: [...nodes[nId].precedents].join(", "),
  // })
  if (seenAlready.length > 0 && seenAlready.indexOf(nId) > -1) {
    return true
  } else {
    seenAlready.push(nId)

    if (nodes[nId].precedents.length > 0) {
      nodes[nId].precedents.forEach((precedent) => {
        // console.log({ nId, precedent })
        isCircular =
          isCircular ||
          nodesTreeDepsCircularCheck(nodes, precedent, seenAlready)
      })
    }
  }
  return isCircular
}
