export const SPACING = "4px"

export const IGNORE = ["2%", "2%"]
export const MOVE = ["6%", "6%"]
export const ID = ["3%", "3%"]
export const POSITION = ["7%", "7%"]
export const UNRESOLVED = ["1%", "1%"]
export const WHO = ["7%", "7%"]
export const WHAT = ["15%", "15%"]
export const WHEN = ["9%", "9%"]
export const SPENT = ["4.5", "4.5%"]
export const DEGREE = ["3%", "3%", "3%", "3%", "3%", "2.5%"]
export const DEADLINE = ["2%", "2%"]
export const FORECAST = ["2%", "2%"]
export const QUALITY = ["2%", "2%"]
export const COMMENT = ["20%"]
export const OK = ["2%", "2%"]
export const PM = ["5%", "5%"] // PlusMinus

export const DEPS = ["4%"]

// const widthTotal =
//   parseFloat(IGNORE[0]) +
//   parseFloat(MOVE[0]) +
//   parseFloat(ID[0]) +
//   parseFloat(POSITION[0]) +
//   parseFloat(WHO[0]) +
//   parseFloat(WHAT[0]) +
//   parseFloat(WHEN[0]) +
//   parseFloat(WHO[0]) +
//   parseFloat(UNRESOLVED[0]) +
//   parseFloat(DEGREE[0]) +
//   parseFloat(DEADLINE[0]) +
//   parseFloat(FORECAST[0]) +
//   parseFloat(QUALITY[0]) +
//   parseFloat(COMMENT[0]) +
//   parseFloat(OK[0]) +
//   parseFloat(PM[0])

// should be <100% otherwise there is nothing
// left for the <Spacer />
