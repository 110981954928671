import { HOURS_PER_DAY } from "../../const/globals"
import { hoursToDur, durToHours } from "../timeHandler/durations"

export function sumOfDuration(array, hoursInDay = HOURS_PER_DAY) {
  let sum = { days: 0, hours: 0 }
  array.forEach((element) => {
    sum.hours += element.hours
    sum.days += element.days + Math.floor(sum.hours / hoursInDay)
    sum.hours = sum.hours % hoursInDay
  })
  return sum
}

/**
 * (c) Jasper Anders
 *
 * Returns a projection duration object based on the
 * linear extrapolation of spent given degree
 * @param {int} degree
 * @param {object} spent
 * @return {object} duration object
 */
export function spentExtrapolate(spent, degree) {
  const spentHours = durToHours(spent)
  let projection = { hours: 0, days: 0 }
  if (degree > 0) {
    projection = hoursToDur(
      Math.ceil((Number(spentHours) / Number(degree)) * 100)
    )
  }
  return projection
}

/**
 * (c) Jasper Anders
 *
 * Returns the minimum int from an array looking only at the elements > 0
 * @param {*} arr
 * @return {int} min
 */
export const arrayMinElement = (arr) => {
  // const temp = arr.filter((num) => num > 0)
  const temp = arr.filter((num) => num > 0)
  return temp.length > 0 ? Math.min(...temp) : 0
}
