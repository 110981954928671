import {
  startOfBusinessDayISO,
  endOfBusinessDayISO,
} from "../../functions/timeHandler/bordersOfBusinessDay"

import personCreate from "../../functions/persons/personCreate"
import nodeCreate from "../../functions/nodes/nodeCreate"
import { nodesTreeDownUp } from "../../functions/nodes/nodesAccumulations2"
// import { nodesListDurationsCalc } from "../../functions/timeHandler/timeHandler2"
import addBusinessDays from "date-fns/addBusinessDays"
import isWeekend from "date-fns/isWeekend"
import { ROOT } from "../../const/globals"

export const projectInitialStateSet = () => {
  let theDay = isWeekend(new Date(Date.now()))
    ? addBusinessDays(new Date(Date.now()), 1)
    : new Date(Date.now())

  let _node1 = nodeCreate({
    pId: ROOT,
    fromWhen: startOfBusinessDayISO(theDay, "9"),
    byWhen: endOfBusinessDayISO(theDay, "17"),
  })
  _node1.fromWhenEarliest = _node1.fromWhen

  let _root = nodeCreate({
    nId: ROOT,
    position: [],
    children: [_node1.nId],
    fromWhen: _node1.fromWhen,
    fromWhenEarliest: _node1.fromWhen,
    byWhen: _node1.byWhen,
  })

  let setup = {
    communicationTool: "",
    dataRepository: "",
    description: "",
    membersSteeringCommittee: [],
    objective: "",
    team: [],
    title: "My Project Title",
    // persons
    agileMaster: "",
    controller: "",
    customer1: "",
    customer2: "",
    manager: "",
    otherVIP: "",
    productOwner: "",
    sponsor: "",
    respChange: "",
    respQuality: "",
    respRisk: "",
    respKnowledge: "",
    // statusComplete cannot be boolean in order to fill the <Select />
    statusCompleted: "In Progress",
    isStatusLocked: false,
  }

  let ratios = {
    expensesBudget: "",
    expensesActual: "",
    // expensesProjection: 0,
    timeBudget: "",
    timeActual: "",
    // timeProjection: 0,
    // degreeOfCompletion: "",
    // degreeOfExpensesSpent: "",
    // degreeOfTimeSpent: "",
    // docToDobs: 0,
    // docToDots: 0,
    unresolvedIssues: 0,
    satisfactionCustomer: "",
    satisfactionTeam: "",
    issuesChangeTL: 0,
    issuesChangeCheck: false,
    issuesQualityTL: 0,
    issuesQualityCheck: false,
    issuesRiskTL: 0,
    issuesRiskCheck: false,
    issuesKnowledgeTL: 0,
    issuesKnowledgeCheck: false,
    todos: [],
  }

  let params = {
    hoursPerDay: "8",
    daysPerWeek: "5",
    daysPerMonths: "22",
    dateFormat: "yyyy-MM-dd|HH",
    dayStart: "9",
    dayEnd: "17",
    isDetailsOn: false,
    isCommentVertical: true,
    isNameFirstPreferred: true,
    pUnresolvedIssues: [0, 0, 2, 4],
    pDOCThroughDOXS: [0, 90, 95, 100],
    pDOCThroughDOTS: [0, 90, 95, 100],
    pSatisfactionCustomer: [0, 50, 70, 90],
    pSatisfactionTeam: [0, 50, 70, 90],
  }

  let todosTypical = [
    { value: "_t001", label: "Acknowledge status" },
    { value: "_t002", label: "Add people" },
    { value: "_t003", label: "Announce project, sponsor and project manager" },
    { value: "_t004", label: "Approve additional expenses budget" },
    { value: "_t005", label: "Approve additional time budget" },
    { value: "_t006", label: "Confirm interest in project" },
    { value: "_t007", label: "Clarify priorities" },
    {
      value: "_t008",
      label: "Communicate project, responsibilities & organizational setup",
    },
    { value: "_t009", label: "Empower Project Manager" },
    { value: "_t010", label: "Make overall objective SMART" },
    { value: "_t011", label: "Openly demonstrate commitment" },
    { value: "_t012", label: "Refine objective" },
    { value: "_t013", label: "Resolve conflict" },
    { value: "_t014", label: "Support with external resources" },
    { value: "_t015", label: "Support with IT resources" },
  ]

  let rolesStandard = [
    { value: "_r001", label: "Customer 1" },
    { value: "_r002", label: "Customer 2" },
    { value: "_r003", label: "Other VIP" },
    { value: "_r004", label: "Project Sponsor" },
    { value: "_r005", label: "Steering Committee Member" },
    { value: "_r006", label: "Project Manager" },
    {
      value: "_r007",
      label: "Product Owner",
    },
    { value: "_r008", label: "Project Controller" },
    { value: "_r009", label: "Agile Master" },
    { value: "_r010", label: "Resp. Change Mgt" },
    { value: "_r011", label: "Resp. Quality Mgt" },
    { value: "_r012", label: "Resp. Risk Mgt" },
    { value: "_r013", label: "Resp. Knowledge Mgt" },
    { value: "_r014", label: "Team Member" },
  ]

  let project = {
    dates: {
      [_root.fromWhen]: {
        dId: _root.fromWhen,
        nodes: {
          _root,
          [_node1.nId]: _node1,
        },
        rows: [ROOT, _node1.nId],
        setup,
        ratios,
        persons: {},
        todosAdded: [],
      },
    },
    dateInit: _root.fromWhen,
    datesStatusList: [_root.fromWhen],
    dateStatusCurrent: _root.fromWhen,
    dateStatusNext: "",
    dateStatusNextPlanned: "",
    params,
    rolesStandard,
    todosTypical,
    messageFromProject: "",
    isResetForm: false,
  }

  let person = personCreate()
  project.dates[_root.fromWhen].persons[person.rId] = { ...person }

  // initial byWhenFct, span, projection, slack
  // project.dates[_root.fromWhen].nodes = nodesListDurationsCalc(
  //   project.dates[_root.fromWhen].nodes
  // )

  project.dates[_root.fromWhen].nodes = nodesTreeDownUp(
    project.dates[_root.fromWhen].nodes,
    ROOT,
    _root.fromWhen,
    {
      doPositions: false,
      doDependenciesClean: false,
      doFromByWhens: true,
      doTrafficLights: false,
      doEarliestLatest: true,
    }
  )

  return project
}

let projectInitialState = {
  project: projectInitialStateSet(),
}

export default projectInitialState
