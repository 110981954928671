import React from "react"
import { Tooltip, Box } from "@chakra-ui/react"

const StatusUpload = ({ isWarning, isError }) => {
  const errorMsg =
    "ERROR: File or parameters are corrupted. Parameters were not taken over."

  const warningMsg =
    "WARNING: Parameters were taken over but they are from a previous version. Please doublecheck your project plan."

  const okMsg = "OK: Upload successful."

  let message = isError ? errorMsg : isWarning ? warningMsg : okMsg

  let color = isError ? "white" : isWarning ? "black" : "white"
  let bg = isError ? "red.500" : isWarning ? "yellow.500" : "green.500"

  return (
    <Tooltip
      label={message}
      placement="right"
      color={color}
      bg={bg}
      isOpen
      hasArrow
    >
      <Box w="1px"></Box>
    </Tooltip>
  )
}

export default StatusUpload
