import React from "react"
import { connect } from "react-redux"
import { Link as LinkRouter } from "react-router-dom"
import { chakra, Divider, Flex, Heading, Text, Link } from "@chakra-ui/react"
import psLogo from "../../img/psLogo1.svg"
import { isTodayShownUpdate } from "../../redux/actions/settings"
import ParamsUpload from "../ParamsUpload"
import ParamsDownload from "../ParamsDownload"
import { META_URL_SITE } from "../../const/urlCRA"

const PageHeader = ({ settings, dispatch }) => {
  //=================================================================

  const { isTodayShown } = settings

  const date = new Date()
  const today = date.toISOString().split("T")[0]

  //-----------------------------------------------------------------
  const onClick = () => dispatch(isTodayShownUpdate())

  //=================================================================

  return (
    <>
      <Flex justify="space-between" py={1}>
        <Flex flex="0 0 auto">
          <chakra.img
            src={psLogo}
            h="62px"
            pt={2}
            flex="0 0 auto"
            mr={2}
            alt=""
          />
          <Link
            href={META_URL_SITE + "/"}
            _hover={{ color: "grey.800" }}
            flex="0 0 auto"
          >
            <Heading fontWeight="semibold" flex="0 0 auto">
              THE PROJECT STATUS{" "}
            </Heading>
            <Text flex="0 0 auto">
              For Agile & Classical Project Management
            </Text>
          </Link>
        </Flex>

        <Text
          fontSize="lg"
          color="blueGrey.400"
          ml={4}
          textAlign="right"
          display={["none", "inline-block"]}
        >
          "Project Management without status reports is like an aeroplane
          without instruments. You would basically fly blind."
        </Text>
      </Flex>

      <Flex justify="space-between" mb={2} wrap="wrap">
        <Text ml="35px">
          {META_URL_SITE} ★★
          <chakra.button type="button" onClick={onClick} outline="none">
            ★
          </chakra.button>
          ★★ {isTodayShown && today}
        </Text>
        <Flex flex="0 0 auto" justify="space-around" ml="auto">
          <ParamsDownload />
          <ParamsUpload />
        </Flex>
        <Flex align="baseline" justify="flex-end" ml="auto" wrap="wrap">
          <Link as={LinkRouter} to="/" ml={4}>
            Status Sheet
          </Link>
          {/* <Link as={LinkRouter} to="/progress" ml={4}>
            Progress
          </Link> */}
          <Link as={LinkRouter} to="/persons" ml={4}>
            Persons
          </Link>
          <Link as={LinkRouter} to="/setup" ml={4}>
            Setup
          </Link>
          <Text ml={2}>|</Text>
          <Link href={META_URL_SITE + "/"} ml={2}>
            Start
          </Link>
          <Link href={META_URL_SITE + "/infos"} ml={4}>
            Infos
          </Link>
          <Link href={META_URL_SITE + "/about"} ml={4}>
            About
          </Link>
          <Link href={META_URL_SITE + "/blog"} ml={4}>
            Blog
          </Link>
        </Flex>
      </Flex>
      <Divider mb={4} />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  }
}

export default connect(mapStateToProps)(PageHeader)
