import React from "react"
import { connect } from "react-redux"
import CreatableSelect from "react-select/creatable"
import getOptionsPersons from "../../redux/reselect/getOptionsPersons"
import customStyles from "./style/customStyles"

import CustomClearIndicator from "./CustomClearIndicator"

import { selectPersonUpdate } from "../../redux/actions/project"
import objDeepTrim from "../../functions/utils/objDeepTrim"

const SelectPerson = ({
  sId,
  name, // name refers to who or toWhom
  value, // name refers to who or toWhom
  optionsPersons,
  isDisabled,
  isIgnored = false,
  isLocked = false,
  dispatch,
}) => {
  // ================================================================
  const onChange = (data, actionMeta) => {
    // data.label has either the rId or - if new - the new name
    // select Id = sId has nId here

    let payload

    if (actionMeta.action === "clear") {
      payload = { sId, name, value: "", isNew: false }
    } else if (data.__isNew__) {
      payload = { sId, name, personName: data.label, isNew: true }
    } else {
      payload = { sId, name, value: data.value, isNew: false }
    }

    objDeepTrim(payload)

    dispatch(selectPersonUpdate(payload))
  }

  // ---------------------------------------------------------------
  let label = ""
  let index = optionsPersons.findIndex((obj, index) => obj.value === value)
  if (index > -1) {
    label = optionsPersons[index].label
  }

  // ================================================================
  return (
    <CreatableSelect
      backspaceRemovesValue={true}
      isClearable
      escapeClearsValue={true}
      onChange={onChange}
      // requires an object
      value={{ value, label }}
      options={optionsPersons}
      styles={customStyles}
      isDisabled={isLocked}
      isLocked={isLocked}
      isIgnored={isIgnored}
      components={{
        ClearIndicator: CustomClearIndicator,
        DropdownIndicator: null,
        IndicatorSeparator: null,
        Placeholder: () => null,
      }}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    optionsPersons: getOptionsPersons(state),
  }
}

export default connect(mapStateToProps)(SelectPerson)
