/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generates a displayNameShort from firstName and LastName
 * @param {string} nameFirst
 * @param {string} nameLast
 * @param {boolean} isNameFirstPreferred
 * @return {string} isNameFirstPreferred
 */
export function nameDisplayShortGen(
  nameFirst,
  nameLast,
  isNameFirstPreferred = "true"
) {
  if (nameFirst === undefined || nameFirst === null) {
    nameFirst = ""
  }
  if (nameLast === undefined || nameFirst === null) {
    nameLast = ""
  }
  let nameDisplayShort = ""

  if (nameFirst === "" && nameLast === "") {
    nameDisplayShort = ""
  } else if (nameFirst === "" || nameLast === "") {
    nameDisplayShort = nameFirst + nameLast
  } else if (isNameFirstPreferred) {
    nameDisplayShort = nameFirst + " " + nameLast[0]
  } else {
    nameDisplayShort = nameLast + " " + nameFirst[0]
  }

  return nameDisplayShort
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generates a displayNameLong from firstName and LastName
 * @param {string} nameFirst
 * @param {string} nameLast
 * @param {boolean} isNameFirstPreferred
 * @return {string} isNameFirstPreferred
 */
export function nameDisplayLongGen(nameFirst, nameLast, academicTitle = "") {
  let nameDisplayLong = ""

  nameFirst.trim()
  nameLast.trim()

  if (nameFirst === "" && nameLast === "") {
    nameDisplayLong = ""
  } else if (nameFirst === "" || nameLast === "") {
    nameDisplayLong = academicTitle + " " + nameFirst + nameLast
  } else {
    nameDisplayLong = academicTitle + " " + nameFirst + " " + nameLast
  }

  nameDisplayLong = nameDisplayLong.trim()

  return nameDisplayLong
}
