import { copyDeep } from "../utils/objectCopyDeep"
import nodesRowsGen from "./nodesRowsGen"
import { positionToNId } from "./nodesPositionsGen"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Transforms a precedentsStr string into an array of positions
 * @param {string} precedentsStr
 * @return {array} positions
 */
export function precedentsStrToPositionsArr(precedentsStr) {
  if (precedentsStr.trim() === "") {
    return []
  }

  // "01.03, 01.04"
  let positionsArr = precedentsStr.split(",").filter((el) => !!el)
  // → ["01.03", "01.04"]

  positionsArr = positionsArr.map((posStr) => posStr.split("."))
  // → [["01", "03"], ["02","04"]]

  positionsArr = positionsArr.map((posArr) => posArr.map((el) => parseInt(el)))
  // → [[1, 3], [2, 4]]

  return positionsArr
}

/**
 * v1.0.0: (c) Jasper Anders
 * v1.0.1: (c) Prof. Dr. Anders
 *
 * Transforms a positions array into a nId array, e.g.
 * [[1, 2], [2, 4]] → ["de4tyd", "opl9iu"]
 * @param {object} nodes
 * @param {array} posArr array of position arrays
 * @returns {array} nIdArr
 */
export function posArrToNIdArr(nodes, posArr) {
  const nIdArr = [...posArr.map((position) => positionToNId(nodes, position))]
  return nIdArr
}

/**
 * v1.0.0: (c) Jasper Anders
 * v1.0.1: (c) Prof. Dr. Ulrich Anders
 *
 * Transform a precedentsStr string into an array of nIds.
 * Does not allow ancestors, children or children's children to be precedents
 * and filters them out.
 * Example:
 * positionsArrValidated: [[1, 1], [2, 4, 3, 1], [3]]
 * // → to become nodes[nId].precedents after posArrToNIdArr
 * nodes[nId].position: [1, 1, 2]
 * @param {object} nodes
 * @param {string} precedentsStr
 * @return {array} array of nIds
 */
export function depsPosStrToNIdArr(nodes, nId, precedentsStr) {
  const positionsArr = precedentsStrToPositionsArr(precedentsStr)

  // Every position is an array comprised of array elements.
  // It will be checked if the shorter array is exactly in the longer.
  // If yes, it is not valid and will be filtered out.
  const positionsArrValidated = positionsArr.filter((positionArr) => {
    const positionLonger =
      nodes[nId].position.length > positionArr.length
        ? nodes[nId].position
        : positionArr

    const positionShorter =
      nodes[nId].position.length <= positionArr.length
        ? nodes[nId].position
        : positionArr

    const isPositionShorterInLonger = positionShorter.every((el, index) => {
      if (el !== positionLonger[index]) {
        return false
      }
      return true
    })
    return !isPositionShorterInLonger
  })

  return posArrToNIdArr(nodes, positionsArrValidated)
}

/**
 * v1.0.0: (c) Jasper Anders
 * v1.0.1: (c) Prof. Dr. Ulrich Anders
 *
 * Transforms a dependencies array to a positionStr, e.g.
 * ["mu7ikl", "qwe2uv"] → "02.03, 04.02"
 * @param {object} nodes
 * @param {array} depsArr
 * @return {string} posStr
 */
export function depsArrToPosStr(nodes, depsArr) {
  if (depsArr.length === 0) {
    return ""
  }
  let posStr = depsArr
    .map((el) => {
      if (!nodes[el]) {
        return ""
      }
      return nodes[el].position
        .map((pos) => (pos.length > 1 ? `${pos}` : `0${pos}`))
        .join(".")
    })
    .join(", ")

  return posStr
}

/**
 * v1.0.0: (c) Jasper Anders
 * v1.1.0: (c) Prof. Dr. Ulrich Anders
 *
 * Sets the precedents of node nId but filters out any ancestors.
 * Adds nId to the dependents of each precedent
 * @param {object} nodes
 * @param {string} nId
 * @param {array} precedentsStr a dep. string as it comes from the form
 */
export function nodesDependenciesSet(nodes, nId, precedentsStr) {
  const nodesNew = copyDeep(nodes)

  // ["01.02", "02.04", "42.42"]
  const nIdPosArr = depsPosStrToNIdArr(nodesNew, nId, precedentsStr)
  // → ["d4rio2", "eu8ihg", ""]

  // filter out undefined AND ""
  const nIdPosArrClean = nIdPosArr.filter((el) => el.length > 0)

  nodesNew[nId].precedents = nIdPosArrClean

  nodesNew[nId].precedentsStr = depsArrToPosStr(
    nodesNew,
    nodesNew[nId].precedents
  )

  // set dependents
  nIdPosArrClean.forEach((precedentNId) => {
    nodesNew[precedentNId].dependents.push(nId)
  })

  return nodesNew
}

/**
 * (c) Jasper Anders
 *
 * For all: Deletes all dependent from node.dependents if it is not
 * listed in the node.precedents from the dependent
 * OR: Clean dependent if not in precedents
 * @param {object} nodes
 * @return {object} nodesNew
 */
export function cleanDependentsAll(nodes) {
  const nodesNew = copyDeep(nodes)
  const allNodes = nodesRowsGen(nodes)

  allNodes.forEach((nId) => {
    // check every dependent in every node.dependents
    nodesNew[nId].dependents.forEach((dependent, index) => {
      // if this dependent is NOT in nodes.precedents then delete from node.dependents
      if (
        !nodesNew[dependent].precedents.some((precedent) => precedent === nId)
      ) {
        nodesNew[nId].dependents.splice(index, 1)
      }
    })
  })

  return nodesNew
}

/**
 * (c) Jasper Anders
 *
 * NOT NEEDED
 * For one nId: Deletes all dependent from node.dependents if it is not
 * listed in the node.precedents from the dependent
 * OR: Clean dependent if not in precedents
 * @param {object} nodes
 * @return {object} nodesNew
 */
export function cleanDependents(nodes, nId) {
  const nodesNew = copyDeep(nodes)

  nodesNew[nId].dependents.forEach((dependent, index) => {
    // if this dependent is NOT in nodes.precedents then delete from node.dependents
    if (
      !nodesNew[dependent].precedents.some((precedent) => precedent === nId)
    ) {
      nodesNew[nId].dependents.splice(index, 1)
    }
  })

  return nodesNew
}

/**
 * (c) Jasper Anders
 *
 * NOT NEEDED
 * For all: Delete precedent from node.precedents if it is not
 * listed in the node.dependents from the precedent
 * OR: Clean precedent if not in dependents
 * @param {object} nodes
 * @return {object} nodesNew
 */

export function cleanPrecedentsAll(nodes) {
  const nodesNew = copyDeep(nodes)
  const allNodes = nodesRowsGen(nodes)

  allNodes.forEach((nId) => {
    nodesNew[nId].precedents.forEach((precedent, index) => {
      if (
        !nodesNew[precedent].dependents.some((dependent) => dependent === nId)
      ) {
        nodesNew[nId].precedent.splice(index, 1)
      }
    })
  })

  return nodesNew
}

/**
 * (c) Jasper Anders
 *
 * NOT NEEDED
 * For one nId: Delete precedent from node.precedents if it is not
 * listed in the node.dependents from the precedent
 * OR: Clean precedent if not in dependents
 * @param {object} nodes
 * @return {object} nodesNew
 */

export function cleanPrecedents(nodes, nId) {
  const nodesNew = copyDeep(nodes)

  nodesNew[nId].precedents.forEach((precedent, index) => {
    if (
      !nodesNew[precedent].dependents.some((dependent) => dependent === nId)
    ) {
      nodesNew[nId].precedent.splice(index, 1)
    }
  })

  return nodesNew
}
