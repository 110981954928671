import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { Provider as StoreProvider } from "react-redux"
import { store, persistor } from "../../redux/store"
import { PersistGate } from "redux-persist/integration/react"
import "@fontsource/fira-sans/400.css"
import "@fontsource/fira-sans/500.css"
import "@fontsource/fira-sans/600.css"
import "@fontsource/fira-sans/700.css"
import "@fontsource/fira-sans-condensed/400.css"
import "@fontsource/fira-sans-condensed/500.css"
import "@fontsource/fira-sans-condensed/600.css"
import "@fontsource/fira-sans-condensed/700.css"
import "@fontsource/fira-sans-extra-condensed/400.css"
import "@fontsource/fira-sans-extra-condensed/500.css"
import "@fontsource/fira-sans-extra-condensed/600.css"
import "@fontsource/fira-sans-extra-condensed/700.css"
import "@fontsource/inconsolata/400.css"
import "@fontsource/inconsolata/500.css"
import "@fontsource/inconsolata/600.css"
import "@fontsource/inconsolata/700.css"

import { HelmetProvider } from "react-helmet-async"
import { ChakraProvider } from "@chakra-ui/react"
import theme from "../../theme"
import { IconContext } from "react-icons"
import "./style/App.css"

import App from "../App"
import ScrollToTop from "../ScrollToTop"

const AppWrapper = () => {
  // ====================================================================
  return (
    <Router>
      <HelmetProvider>
        <ChakraProvider resetCSS={true} theme={theme}>
          <IconContext.Provider value={{ className: "react-icons" }}>
            <StoreProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <ScrollToTop />
                <App persistor={persistor} />
              </PersistGate>
            </StoreProvider>
          </IconContext.Provider>
        </ChakraProvider>
      </HelmetProvider>
    </Router>
  )
}

export default AppWrapper
