import differenceInBusinessDays from "date-fns/differenceInBusinessDays"
import min from "date-fns/min"
import max from "date-fns/max"
import addHours from "date-fns/addHours"
import addBusinessDays from "date-fns/addBusinessDays"
import subBusinessDays from "date-fns/subBusinessDays"
import isEqual from "date-fns/isEqual"
import getHours from "date-fns/getHours"
import parseISO from "date-fns/parseISO"
import startOfDay from "date-fns/startOfDay"
import isWeekend from "date-fns/isWeekend"
import setHours from "date-fns/setHours"

import { startOfBusinessDay, endOfBusinessDay } from "./bordersOfBusinessDay"
import { DAY_END, DAY_START, ROOT } from "../../const/globals"
import { byWhenAcc, fromWhenAcc, nodesApply } from "../nodes/nodesAccumulations"
import nodesRowsGen from "../nodes/nodesRowsGen"
import { copyDeep } from "../utils/objectCopyDeep"
import { durToHours, hoursToDur } from "./durations"

/**
 * (c) Jasper Anders
 *
 * Calculates and mutates the slack in node nId: slack = span - projection
 * Returns all nodes
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes
 */
export function slackCalc(nodes, nId) {
  // slack is:
  const spanHours = durToHours(nodes[nId].span)
  const projectionHours = durToHours(nodes[nId].projection)
  if (nodes[nId].children.length > 0) {
    nodes[nId].slack = { days: 0, hours: 0 }
  } else {
    nodes[nId].slack = hoursToDur(spanHours - projectionHours)
  }
  return nodes
}

/**
 * (c) Jasper Anders
 * QA0: Ulrich Anders
 *
 * Transforms the byWhenFct to span
 * @param {ISO} fromWhen
 * @param {object} byWhenFct
 * @param {int} businessDayStart
 * @param {int} businessDayEnd
 * @returns {object} span
 */
export function spanCalcFromByWhenFct(
  fromWhen,
  byWhenFct,
  businessDayStart = DAY_START,
  businessDayEnd = DAY_END
) {
  const span = copyDeep(byWhenFct)

  const hoursFromWhen = getHours(parseISO(fromWhen))
  const isDuringDayFromWhen =
    hoursFromWhen < businessDayEnd && hoursFromWhen > businessDayStart

  let hours = 0
  let days = span.days
  if (isDuringDayFromWhen) {
    if (span.hours >= 0) {
      if (span.hours + hoursFromWhen > businessDayEnd) {
        days = span.days
        hours = businessDayEnd - hoursFromWhen
      } else {
        hours = span.hours
      }
    } else {
      if (span.hours + hoursFromWhen < businessDayStart) {
        days = span.days - 1
        hours = 0
        hours += businessDayEnd - hoursFromWhen
      } else {
        days = span.days - 1
        hours = hoursFromWhen + span.hours - businessDayStart
        hours += businessDayEnd - hoursFromWhen
      }
    }
  } else {
    if (span.hours >= 0) {
      if (span.hours + hoursFromWhen > businessDayEnd) {
        days = span.days + 1
        hours = 0
      } else if (span.hours + hoursFromWhen < businessDayStart) {
        days = span.days
        hours = 0
      } else {
        hours = hoursFromWhen + span.hours - businessDayStart
      }
    } else {
      if (span.hours + hoursFromWhen > businessDayEnd) {
        days = span.days + 1
        hours = 0
      } else if (
        hoursFromWhen > businessDayEnd &&
        span.hours + hoursFromWhen < businessDayStart
      ) {
        days = span.days - 1
        hours = 0
      } else if (span.hours + hoursFromWhen < businessDayStart) {
        days = span.days
        hours = 0
      } else {
        days = span.days - 1
        hours = hoursFromWhen + span.hours - businessDayStart
      }
    }
  }

  span.hours = hours
  span.days = days

  return span
}

/**
 * (c) Jasper Anders
 *
 *
 * Calculates and mutates the span for one node based on the byWhenFct.
 * Returns all nodes
 * @param {object} nodes
 * @param {string} nId
 * @param {number} hourDayEnd end of day
 * @returns {object} nodes
 */
export function spanCalc(nodes, nId) {
  nodes[nId].span = spanCalcFromByWhenFct(
    nodes[nId].fromWhen,
    nodes[nId].byWhenFct
  )
  return nodes
}

/**
 * (c) Jasper Anders
 *
 *
 * Calculates Span and Slack for all nodes in the tree.
 * @param {object} nodes
 * @returns {object} nodes
 */
export function nodesTreeSpanSlackPipe(nodes) {
  const nodesNew = copyDeep(nodes)
  const nodesSpan = nodesApply(nodesNew, ROOT, spanCalc)
  const nodesSlack = nodesApply(nodesSpan, ROOT, slackCalc)
  return nodesSlack
}

/**
 * (c) Jasper Anders
 * Adds one hour to a date, respecting DAY_START and DAY_END
 * @param {isoDateString} date
 * @returns {isoDateString}
 */
export function addOneHour(
  date,
  businessDayStart = DAY_START,
  businessDayEnd = DAY_END
) {
  let dateNew = addHours(parseISO(date), 1)
  if (getHours(dateNew) >= businessDayEnd || isWeekend(dateNew)) {
    dateNew = addBusinessDays(dateNew, 1)
    dateNew = startOfDay(dateNew)
    dateNew = setHours(dateNew, businessDayStart)
  }
  return dateNew.toISOString()
}

/**
 * (c) Jasper Anders
 * QA0: Ulrich Anders
 *
 * Calculates and mutates fromWhen of node nId dependent
 * on the byWhen of the precedents and the fromWhen of the parent
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes
 */
export function fromWhenCalculate(nodes, nId) {
  const { precedents, pId } = nodes[nId]
  // check if nId is root an set pId accordingly as root has no parent.
  const dates = []
  !!precedents.length &&
    precedents.forEach((precedent) => {
      dates.push(parseISO(addOneHour(nodes[precedent].byWhen)))
    })
  dates.push(parseISO(nodes[ROOT].fromWhen))
  if (pId !== "") {
    dates.push(parseISO(nodes[pId].fromWhen))
  }
  // find earliest possible but latest necessary date
  const fromWhenLatest = max(dates)

  nodes[nId].fromWhen = fromWhenLatest.toISOString()
  return nodes
}

/**
 * (c) Jasper Anders
 * QA0: Ulrich Anders
 *
 * Calculates byWhen for ONE node nId in nodesNew
 * based in its byWhenFct added to fromWhen.
 * Return nodesNew
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodesNew
 */
export function byWhenCalculate(nodes, nId) {
  let nodesNew = copyDeep(nodes)

  const dates = []
  nodesNew[nId].byWhenLatest && dates.push(parseISO(nodesNew[nId].byWhenLatest))

  dates.push(
    dateAddDuration(parseISO(nodesNew[nId].fromWhen), nodesNew[nId].byWhenFct)
  )

  nodesNew[nId].byWhen = min(dates).toISOString()
  if (nodes[nId].byWhen !== nodesNew[nId].byWhen) {
    // update byWhenFct
    nodesNew = byWhenFctSet(nodesNew, nId)
  }

  return nodesNew
}

/**
 * (c) Jasper Anders
 *
 *
 * A function that can be used to recalculate from- and byWhen. This can
 * be used to recalculate if precedents have changed.
 * @param {object} nodes
 * @param {string} nId
 */
export function nodesFromByWhenPipe(nodes, nId) {
  let nodesNew = copyDeep(nodes)
  nodesNew = nodeByWhenLatestParentRestrictionCalc(nodesNew, nId)
  nodesNew = fromWhenCalculate(nodesNew, nId)
  nodesNew = byWhenCalculate(nodesNew, nId)

  nodesNew[nId].children.forEach((dependent) => {
    nodesNew = nodesFromByWhenPipe(nodesNew, dependent)
  })

  nodesNew[nId].dependents.forEach((dependent) => {
    nodesNew = nodesFromByWhenPipe(nodesNew, dependent)
  })

  nodesNew = fromWhenAcc(nodesNew)
  nodesNew = byWhenAcc(nodesNew)
  nodesNew = byWhenFctSet(nodesNew, nId)
  return nodesNew
}

/**
 * (c) Jasper Anders
 * A wrapper for nodesFromByWhenPipe.
 * This function calculates the following:
 * - byWhenLatest depending on its (grand) Parents
 * - fromWhen depending on its precedents
 * - byWhen depending on the byWhenFct
 *
 * This is repeated for all of a nodes children and dependents
 * afterwards
 *
 * - byWhen is accumulated for nodes that are not leaves and not pinned
 * - byWhenFct is newly calculated because the above action could have changed the byWhen
 *
 * !! Caution: The design of this does not allow for the calculation of byWhenLatest if
 * !! it is restricted by a dependent. For this you must also (!) use
 * !! nodesByWhenAccumulateDependencyRestriction in your reducer.
 * @param {object} nodes
 * @param {string} nId
 * @param {boolean}
 * @returns {object} nodes
 */
export function nodesFromByWhenPipeWrapper(nodes, nId) {
  let nodesNew = copyDeep(nodes)
  try {
    // set and accumulate first
    nodesNew = nodesFromByWhenPipe(nodesNew, nId)
    // accumulate to all precedents again
    nodesNew = nodesFromByWhenPipe(nodesNew, ROOT)
  } catch (error) {
    console.error(error)
    console.log("Make sure you don't have circular precedents.")
  }
  return nodesNew
}

/**
 * (c) Jasper Anders
 *
 * Adds a duration with business days and hours to a given date.
 * Returns the new business date.
 * @param {Date} date
 * @param {number} hours (business) to add to the provided date
 * @returns {Date} businessDateNew
 */
export function dateAddDuration(
  date,
  { days, hours },
  businessDayStart = DAY_START,
  businessDayEnd = DAY_END
) {
  const businessDayNew = addBusinessDays(date, days)
  let businessDateNew = businessDayNew

  if (getHours(businessDayNew) + hours > businessDayEnd) {
    businessDateNew = addBusinessDays(businessDateNew, 1)
    businessDateNew = setHours(businessDateNew, businessDayStart)
    businessDateNew = addHours(
      businessDateNew,
      hours - (businessDayEnd - getHours(date))
    )
  } else if (getHours(businessDayNew) + hours < businessDayStart) {
    businessDateNew = setHours(businessDateNew, businessDayStart)
    businessDateNew = addHours(
      businessDateNew,
      hours - (getHours(date) - businessDayStart)
    )
  } else {
    businessDateNew = addHours(businessDayNew, hours)
  }

  // if resulting date is start of day set to end of day from last business day.
  if (
    isEqual(
      businessDateNew,
      startOfBusinessDay(businessDateNew, businessDayStart)
    )
  ) {
    businessDateNew = endOfBusinessDay(
      subBusinessDays(businessDateNew, 1),
      businessDayEnd
    )
  }

  // return businessDateNew.toISOString()
  return businessDateNew
}

/**
 * (c) Jasper Anders
 *
 *
 * Calculates for ONE node nId the byWhenFct depending on its
 * fromWhen and byWhen props and mutates this in nodesNew.
 * Returns nodesNew.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodesNew
 */
export function byWhenFctSet(
  nodes,
  nId,
  businessDayStart = DAY_START,
  businessDayEnd = DAY_END
) {
  let nodesNew = copyDeep(nodes)
  const { byWhen, fromWhen } = nodesNew[nId]
  let byWhenDate = parseISO(byWhen)
  let fromWhenDate = parseISO(fromWhen)

  let businessDays = differenceInBusinessDays(byWhenDate, fromWhenDate)
  const hoursDiff = getHours(byWhenDate) - getHours(fromWhenDate)
  let hours = hoursDiff

  if (getHours(fromWhenDate) + hoursDiff > businessDayEnd) {
    const remainHours = hours - businessDayEnd
    businessDays += 1
    hours = remainHours
  } else if (getHours(fromWhenDate) + hoursDiff <= businessDayStart) {
    const remainHours = businessDayEnd - (businessDayStart - hours)
    businessDays -= 1
    hours = remainHours
  }

  nodesNew[nId].byWhenFct = {
    days: businessDays ? businessDays : 0,
    hours: hours ? hours : 0,
  }
  return nodesNew
}

/**
 * (c) Jasper Anders
 *
 *
 * Calculates the byWhenLatest for a node restricted by its parent
 * @param {object} nodes
 * @param {string} nId
 */
export function nodeByWhenLatestParentRestrictionCalc(nodes, nId) {
  let nodesNew = copyDeep(nodes)

  const byWhenRestrictionCalc = (nodes, nId) => {
    if (nId === ROOT) {
      return nodes
    }
    const { pId } = nodes[nId]
    const dates = []

    nodesNew[pId].byWhenLatest &&
      dates.push(new Date(nodesNew[pId].byWhenLatest))
    nodesNew[nId].byWhenLatest &&
      dates.push(new Date(nodesNew[nId].byWhenLatest))

    if (!!dates.length) {
      nodes[nId].byWhenLatest = min([...dates])
    }

    return nodes
  }

  return nodesApply(nodesNew, ROOT, byWhenRestrictionCalc)
}

/**
 * (c) Jasper Anders
 * A function that accumulates byWhen and byWhen latest taking its precedents
 * into consideration. If a node is pinned its precedent cannot overshoot the
 * pinned value. For this exact case we need this function. It calculates:
 *
 * - the byWhenLatest of all nodes
 * - and sets the byWhen accordingly (if it was later than allowed)
 *
 * !! Caution you also need to use nodesFromByWhenPipeWrapper in your reducer.
 * !! For more info take a look at the nodesFromByWhenPipeWrapper
 *
 * @param {object} nodes
 * @returns {object} nodes
 */
export function nodesByWhenAccumulateDependencyRestriction(nodes) {
  const allNodes = nodesRowsGen(nodes)
  // reset byWhenLatestValues
  allNodes.forEach((nId) => {
    if (nodes[nId].isByWhenPinned) {
      nodes[nId].byWhenLatest = nodes[nId].byWhen
    } else {
      nodes[nId].byWhenLatest = ""
    }
  })

  const iterator = allNodes[Symbol.iterator]()
  while (true) {
    const nIdCurrent = iterator.next().value

    if (typeof nIdCurrent === "undefined") break

    const {
      precedents,
      dependents,
      // children,
      byWhen,
      // byWhenLatest,
      pId,
    } = nodes[nIdCurrent]

    const byWhenLatestArr = []
    const byWhenArr = [new Date(byWhen)]

    // add byWhenLatest dates of dependents
    dependents &&
      dependents.forEach((dependent) => {
        nodes[dependent].byWhenLatest &&
          nodes[dependent].byWhenLatest !== "" &&
          byWhenLatestArr.push(new Date(nodes[dependent].byWhenLatest))
      })

    !!pId.length &&
      nodes[pId].isByWhenPinned &&
      byWhenLatestArr.push(new Date(nodes[pId].byWhenLatest))
    // date-fns min over dates
    let byWhenLatestNew = !!byWhenLatestArr.length
      ? min([...byWhenLatestArr])
      : false

    byWhenLatestNew = byWhenLatestNew
      ? byWhenLatestNew.toISOString()
      : nodes[nIdCurrent].byWhenLatest

    byWhenLatestNew && byWhenArr.push(new Date(byWhenLatestNew))

    if (byWhenLatestNew !== nodes[nIdCurrent].byWhenLatest) {
      nodes[nIdCurrent].byWhenLatest = byWhenLatestNew
      nodes[nIdCurrent].byWhen = min(byWhenArr).toISOString()
      allNodes.push(...precedents)
      if (nIdCurrent !== ROOT) allNodes.push(pId)
    }
  }

  return nodes
}
