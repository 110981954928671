import strCapitalize from "../utils/strCapitalize"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generates names from an overall personName
 * @param {string} personName
 * @param {boolean} isNameFirstPreferred
 * @returns {object} names
 */
const namesGen = (personName, isNameFirstPreferred) => {
  let names = {
    nameFirst: "",
    nameLast: "",
    nameDisplayShort: "",
    nameDisplayLong: "",
  }
  personName = strCapitalize(personName)

  if (isNameFirstPreferred) {
    if (personName.indexOf(" ") > -1) {
      names.nameFirst = personName.split(" ")[0]
      names.nameLast = strCapitalize(personName.split(" ")[1])
      names.nameDisplayShort = names.nameFirst + " " + names.nameLast[0]
      names.nameDisplayLong = names.nameFirst + " " + names.nameLast
    } else {
      names.nameFirst = personName
      names.nameLast = ""
      names.nameDisplayShort = personName
      names.nameDisplayLong = personName
    }
  } else {
    if (names.personName.indexOf(" ") > -1) {
      names.nameLast = personName.split(" ")[0]
      names.nameFirst = strCapitalize(personName.split(" ")[1])
      names.nameDisplayShort = names.nameLast + " " + names.nameFirst[0]
      names.nameDisplayLong = names.nameFirst + " " + names.nameLast
    } else {
      names.nameLast = personName
      names.nameFirst = ""
      names.nameDisplayShort = personName
      names.nameDisplayLong = personName
    }
  }

  return names
}

export default namesGen
