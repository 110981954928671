import {
  startOfBusinessDay,
  endOfBusinessDay,
} from "../../functions/timeHandler/bordersOfBusinessDay"

import parseISO from "date-fns/parseISO"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Make a dateISOString from an own abbreviated format
 * @param {string} dateString
 * @param {string} dateStatusCurrent ISO
 * @returns {string} dateISOString
 */
function locToISO(
  dateString,
  dateStatusCurrent = "",
  isStartOfDay = false,
  dayStart = "9",
  dayEnd = "17"
) {
  if (dateString === undefined || dateString === "") {
    return ""
  }

  const dayStartInt = parseInt(dayStart)
  const dayEndInt = parseInt(dayEnd)

  let hour = ""

  let placeholderYear
  let placeholderMonth

  if (dateStatusCurrent !== "") {
    const status = parseISO(dateStatusCurrent)
    placeholderYear = status.getUTCFullYear()
    placeholderMonth = status.getUTCMonth() + 1
  } else {
    const today = new Date()
    placeholderYear = today.getUTCFullYear()
    placeholderMonth = today.getUTCMonth() + 1
  }

  let splits = dateString
  if (dateString.indexOf("|") > -1) {
    let splits = dateString.split("|")
    if (splits.length > 1) {
      hour = splits[1].trim()
      splits = splits[0].trim()
    } else {
      hour = ""
    }
  } else {
    hour = ""
  }

  splits = splits.split("-")
  splits.push(hour)
  if (splits.length === 2) {
    splits.unshift(placeholderMonth)
  }

  if (splits.length === 3) {
    splits.unshift(placeholderYear)
  }

  splits = splits.map((split) => (split === "" ? 0 : parseInt(split)))
  if (splits[0] > 20 && splits[0] < 100) {
    splits[0] += 2000
  } else if (splits[0] > 2020 && splits[0] < 2100) {
  } else {
    splits[0] = parseInt(placeholderYear)
  }

  splits[1] -= 1 // reduce manual month by 1

  let date = new Date(...splits)
  if (splits[3] === 0 || splits[3] > dayEndInt) {
    date = endOfBusinessDay(date, dayEnd)
  } else if (splits[3] <= dayStartInt) {
    // must be at least startOfBusinessDay + 1 hour
    date = startOfBusinessDay(date, (parseInt(dayStart) + 1).toString())
  }

  if (isStartOfDay) {
    date = startOfBusinessDay(date, dayStart)
  }

  const dateReturn = date
    .toISOString()
    .replace(/59/g, "00")
    .replace(/999/g, "000")

  return dateReturn
}

export default locToISO
