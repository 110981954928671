import React from "react"
import { Flex, Box } from "@chakra-ui/react"
import { colorReduce } from "../../functions/helpers/levelColorSet"

const GanttBox = ({
  w0,
  w1,
  w2,
  w3,
  bg,
  boxW,
  hoursPerDay,
  isBar = false,
  isAfterBarEnd = false,
  isFriday = false,
  isViewWeek = false, // isWeekView
  hasChildren = false,
  isProjection = true,
  isFade = false,
  isWeekend = false,
  isPartialStart = false,
  isPartialEnd = false,
  isByWhenPinned = false,
}) => {
  //=================================================================
  // defaults
  if (isAfterBarEnd) {
    return null
  }

  bg = bg ? bg : "blue.500"

  w0 = w0 ? w0 : 0
  w1 = w1 ? w1 : 0
  w2 = w2 ? w2 : 0
  w3 = w3 ? w3 : 0

  // one hour space between projectionEnd and slackStart
  let w1_2 = 0
  if (!hasChildren) {
    w1_2 = w1 !== 0 && w2 !== 0 ? 1 / hoursPerDay : 0
  }

  w1 = 1 - w0 - w2 - w3 - w1_2

  let bgReduced = bg
  const colorReduction = 200

  if (!isBar) {
    bg = "transparent"
  }

  // only fade if no children
  if (!hasChildren) {
    if (isFade) {
      bgReduced = colorReduce(bg, colorReduction)
    } else if (!isProjection) {
      bg = colorReduce(bg, colorReduction)
    }
  }

  //=================================================================
  return (
    <>
      {isViewWeek ? (
        <>
          {!isBar ? (
            <Box
              minW={(boxW * 2) / 5 + "px"}
              w={(boxW * 2) / 5 + "px"}
              mr={0}
              h={boxW + "px"}
              bg={bg}
            />
          ) : isFade ? (
            <>
              <Box
                minW={(boxW * 1) / 5 + "px"}
                w={(boxW * 1) / 5 + "px"}
                mr={0}
                h={boxW + "px"}
                bg={bg}
              />
              <Box
                minW={(boxW * 1) / 5 + "px"}
                w={(boxW * 1) / 5 + "px"}
                mr={0}
                h={boxW + "px"}
                bg={bgReduced}
              />
            </>
          ) : isPartialStart ? (
            <>
              <Box
                minW={(boxW * 1) / 5 + "px"}
                w={(boxW * 1) / 5 + "px"}
                mr={0}
                h={boxW + "px"}
                bg={"white"}
              />
              <Box
                minW={(boxW * 1) / 5 + "px"}
                w={(boxW * 1) / 5 + "px"}
                mr={0}
                h={boxW + "px"}
                bg={bg}
              />
            </>
          ) : isPartialEnd ? (
            <Box
              minW={(boxW * 1) / 5 + "px"}
              w={(boxW * 1) / 5 + "px"}
              mr={0}
              h={boxW + "px"}
              bg={bg}
            />
          ) : (
            <Box
              minW={(boxW * 2) / 5 + "px"}
              w={(boxW * 2) / 5 + "px"}
              mr={0}
              h={boxW + "px"}
              bg={bg}
            />
          )}
          {isByWhenPinned && (
            <Box
              minW={"2px"}
              w={"2px"}
              mr={0}
              h={boxW + 4 + "px"}
              bg={"black"}
            />
          )}
          {isFriday && !isByWhenPinned && (
            <Box minW={"2px"} w={"2px"} mr={0} h={boxW + "px"} bg={"white"} />
          )}
        </>
      ) : w1 === 1 ? (
        isByWhenPinned ? (
          <>
            <Box
              minW={boxW + "px"}
              w={boxW + "px"}
              mr={0}
              h={boxW + "px"}
              bg={bg}
            />
            <Box
              minW={"2px"}
              w={"2px"}
              mr={0}
              h={boxW + 4 + "px"}
              bg={"black"}
            />
          </>
        ) : (
          <Box
            minW={boxW + "px"}
            w={boxW + "px"}
            mr={"2px"}
            h={boxW + "px"}
            bg={bg}
          />
        )
      ) : isByWhenPinned ? (
        <>
          <Flex minW={boxW + "px"} maxW={boxW + "px"} mr={0} h={boxW + "px"}>
            <Box bg="white" minW={w0 * boxW + "px"} flex="0 0 auto"></Box>
            <Box bg={bg} minW={w1 * boxW + "px"} flex="0 0 auto"></Box>
            <Box bg="white" minW={w1_2 * boxW + "px"} flex="0 0 auto"></Box>
            <Box bg={bgReduced} minW={w2 * boxW + "px"} flex="0 0 auto"></Box>
            <Box bg="white" minW={w3 * boxW + "px"} flex="0 0 auto"></Box>
          </Flex>
          <Box minW={"2px"} w={"2px"} mr={0} h={boxW + 4 + "px"} bg={"black"} />
        </>
      ) : (
        <Flex minW={boxW + "px"} maxW={boxW + "px"} mr={"2px"} h={boxW + "px"}>
          <Box bg="white" minW={w0 * boxW + "px"} flex="0 0 auto"></Box>
          <Box bg={bg} minW={w1 * boxW + "px"} flex="0 0 auto"></Box>
          <Box bg="white" minW={w1_2 * boxW + "px"} flex="0 0 auto"></Box>
          <Box bg={bgReduced} minW={w2 * boxW + "px"} flex="0 0 auto"></Box>
          <Box bg="white" minW={w3 * boxW + "px"} flex="0 0 auto"></Box>
        </Flex>
      )}
    </>
  )
}

export default GanttBox
