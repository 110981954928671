import React, { useRef, useEffect } from "react"
import { useForm } from "react-hook-form"
import {
  chakra,
  Input,
  Flex,
  Box,
  Text,
  Tooltip,
  Checkbox,
} from "@chakra-ui/react"
import SelectTodos from "../SelectTodos"
import { formRatiosUpdate } from "../../redux/actions/project"
import { LabelTop } from "../../componentsWithChakra"
import TrafficLight from "../TrafficLight"
import { issueCheckUpdate, issueTLUpdate } from "../../redux/actions/project"

import {
  NUMBER_MESSAGE,
  NUMBER_REGEX,
  PERCENT_MESSAGE,
  PERCENT_REGEX,
} from "../../const/messages"

import objDeepTrim from "../../functions/utils/objDeepTrim"

const FormRatios = ({ project, settings, isResetForm = false, dispatch }) => {
  //=================================================================
  const { currencySymbol } = settings
  const { dateStatusCurrent } = project
  const { isStatusLocked } = project.dates[dateStatusCurrent].setup

  const inputRef = useRef(null)

  const { register, handleSubmit, reset, errors } = useForm()

  const {
    satisfactionCustomer,
    satisfactionTeam,
    expensesBudget,
    expensesActual,
    issuesChangeTL,
    issuesChangeCheck,
    issuesQualityTL,
    issuesQualityCheck,
    issuesRiskTL,
    issuesRiskCheck,
    issuesKnowledgeTL,
    issuesKnowledgeCheck,
    todos,
  } = project.dates[dateStatusCurrent].ratios

  //-----------------------------------------------------------------
  useEffect(() => {
    if (isResetForm) {
      reset()
    }
  }, [isResetForm, reset])

  //-----------------------------------------------------------------
  const onSubmit = (data) => {
    inputRef.current.focus()
    objDeepTrim(data)
    const payload = { data }
    dispatch(formRatiosUpdate(payload))
  }

  const onClickTL = (e) => {
    const name = e.target.name
    const payload = { name }
    dispatch(issueTLUpdate(payload))
  }

  const onChangeCheck = (e) => {
    const name = e.target.name
    const payload = { name }
    dispatch(issueCheckUpdate(payload))
  }

  //=================================================================
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Flex w="100%" justify="flex-end">
        <Box
          w={["50%", "50%", "25%", "25%"]}
          pl={[0, 0, 0, 0]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label="The Expenses Budget is the amount of budget that has been assigned to the project. Depending on the context it may or may not contain the expenses for employees."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Expenses Budget {currencySymbol}</LabelTop>
          </Tooltip>
          <Tooltip
            isOpen={errors.expensesBudget}
            label={errors.expensesBudget ? errors.expensesBudget.message : ""}
            placement="top-start"
            hasArrow
            bg="red.500"
          >
            <Input
              name="expensesBudget"
              defaultValue={expensesBudget}
              ref={register({
                pattern: {
                  value: NUMBER_REGEX,
                  message: NUMBER_MESSAGE,
                },
              })}
              isDisabled={isStatusLocked}
            />
          </Tooltip>
        </Box>

        <Box
          w={["50%", "50%", "25%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label="Expenses Actual is the amount of the Expenses Budget that has already been spent up until the point of the Current Status Date. You need to add this number from its own sources."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Expenses Actual {currencySymbol}</LabelTop>
          </Tooltip>

          <Input
            name="expensesActual"
            defaultValue={expensesActual}
            ref={register}
            isDisabled={isStatusLocked}
          />
        </Box>

        <Box
          w={["50%", "50%", "25%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label="Customer Satisfaction is a key measure for the project success. Therefore every project should put the customer satisfaction into focus. An evaluation of the customer satisfaction need to be provided from its own sources."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Customer Satisfaction %</LabelTop>
          </Tooltip>
          <Tooltip
            isOpen={errors.satisfactionCustomer}
            label={
              errors.satisfactionCustomer
                ? errors.satisfactionCustomer.message
                : ""
            }
            placement="top-start"
            hasArrow
            bg="red.500"
          >
            <Input
              name="satisfactionCustomer"
              ref={register({
                pattern: {
                  value: PERCENT_REGEX,
                  message: PERCENT_MESSAGE,
                },
              })}
              defaultValue={satisfactionCustomer}
              isDisabled={isStatusLocked}
            />
          </Tooltip>
        </Box>

        <Box
          w={["50%", "50%", "25%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[0, 0, 0, 0]}
        >
          <Tooltip
            label="Team Satisfaction is an important (early warning) indicator for measuring the achievability of the project's (SMART) objective, the overall scope and quality of the project as well as the work conditions under which the project is carried out. Satisfied teams usually achieve much better project results. An evaluation of the customer satisfaction need to be provided from its own sources."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Team Satisfaction %</LabelTop>
          </Tooltip>
          <Tooltip
            isOpen={errors.satisfactionTeam}
            label={
              errors.satisfactionTeam ? errors.satisfactionTeam.message : ""
            }
            placement="top-start"
            hasArrow
            bg="red.500"
          >
            <Input
              name="satisfactionTeam"
              ref={register({
                pattern: {
                  value: PERCENT_REGEX,
                  message: PERCENT_MESSAGE,
                },
              })}
              defaultValue={satisfactionTeam}
              isDisabled={isStatusLocked}
            />
          </Tooltip>
        </Box>
      </Flex>

      <Flex w="100%" wrap="wrap">
        <Box
          w={["50%", "50%", "25%", "25%"]}
          pl={[0, 0, 0, 0]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label="Significant Change Issues refer to fundamental issues in the change management process that tries to introduce the changes from the project into the organization."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Significant Change Issues</LabelTop>
          </Tooltip>
          <Flex>
            <TrafficLight
              name="issuesChangeTL"
              colorCode={issuesChangeTL}
              onClick={onClickTL}
              isDisabled={isStatusLocked}
              mr={2}
            />
            <Text mr={8}>Issues</Text>
            <Checkbox
              name="issuesChangeCheck"
              isChecked={issuesChangeCheck}
              onChange={onChangeCheck}
              isReadOnly={isStatusLocked}
            >
              Measures
            </Checkbox>
          </Flex>
        </Box>

        <Box
          w={["50%", "50%", "25%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label="Significant Quality Issues refer to material quality issues beyond the ones of individual deliverables and that are of overall concern."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Significant Quality Issues</LabelTop>
          </Tooltip>
          <Flex>
            <TrafficLight
              name="issuesQualityTL"
              colorCode={issuesQualityTL}
              onClick={onClickTL}
              isDisabled={isStatusLocked}
              mr={2}
            />
            <Text mr={8}>Issues</Text>
            <Checkbox
              name="issuesQualityCheck"
              isChecked={issuesQualityCheck}
              onChange={onChangeCheck}
              isReadOnly={isStatusLocked}
            >
              Measures
            </Checkbox>
          </Flex>
        </Box>

        <Box
          w={["50%", "50%", "25%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[1, 1, 1, 1]}
        >
          <Tooltip
            label="Significant Risk Issues are issues that have material severity, a certain tangible likelihood, and which are concrete, threatening, and will bring the project success in jeopardy."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Significant Risk Issues</LabelTop>
          </Tooltip>
          <Flex>
            <TrafficLight
              name="issuesRiskTL"
              colorCode={issuesRiskTL}
              onClick={onClickTL}
              isDisabled={isStatusLocked}
              mr={2}
            />
            <Text mr={8}>Issues</Text>
            <Checkbox
              name="issuesRiskCheck"
              isChecked={issuesRiskCheck}
              onChange={onChangeCheck}
              isReadOnly={isStatusLocked}
            >
              Measures
            </Checkbox>
          </Flex>
        </Box>

        <Box
          w={["50%", "50%", "25%", "25%"]}
          pl={[1, 1, 1, 1]}
          pr={[0, 0, 0, 0]}
        >
          <Tooltip
            label="Significant Knowledge Issues are issues of missing, not transferred or not documented knowledge. A loss of knowledge from the acquired knowledge in the project may slow down the project, threaten the handover from the project into the line responsibility or will make the operations after the project difficult or impossible."
            placement="top-start"
            hasArrow
          >
            <LabelTop>Significant Knowledge Issues</LabelTop>
          </Tooltip>
          <Flex>
            <TrafficLight
              name="issuesKnowledgeTL"
              colorCode={issuesKnowledgeTL}
              onClick={onClickTL}
              isDisabled={isStatusLocked}
              mr={2}
            />
            <Text mr={8}>Issues</Text>
            <Checkbox
              name="issuesKnowledgeCheck"
              isChecked={issuesKnowledgeCheck}
              onChange={onChangeCheck}
              isReadOnly={isStatusLocked}
            >
              Measures
            </Checkbox>
          </Flex>
        </Box>
      </Flex>

      <Box w={["100%"]} pl={[0]} pr={[0]}>
        <Tooltip
          label="Top Management TODOs: These are TODOs for the Management that is hierarchical above the project. A project is usually embedded in an organization and sometimes the project manager cannot manage issues outside the project. In these cases, Top Management has to perform certain activities. Select from typical TODOs that are pre-installed or add further TODOs."
          placement="top-start"
          hasArrow
          maxWidth={400}
        >
          <LabelTop>Top Management TODOs</LabelTop>
        </Tooltip>
        <SelectTodos name="todos" value={todos} isLocked={isStatusLocked} />
      </Box>

      {isStatusLocked ? (
        <Box w="100%" h="24px" mb={4}></Box>
      ) : (
        <Box w="100%" order={[10]} mb={4}>
          <chakra.button type="submit" ref={inputRef} outline="none">
            OK
          </chakra.button>
        </Box>
      )}
    </form>
  )
}

export default FormRatios
