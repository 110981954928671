import React, { useState, useLayoutEffect, useRef } from "react"
import { connect } from "react-redux"
import { Box } from "@chakra-ui/react"
import SheetRow from "../SheetRow"
import nodeIsTopCalc from "../../functions/nodes/nodeIsTopCalc"
import nodeIsBottomCalc from "../../functions/nodes/nodeIsBottomCalc"
import getNodesInfos from "../../redux/reselect/getNodesInfos"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"

const SheetRows = ({
  projectCurrent,
  nodesInfos,
  dateStatusCurrent,
  params,
  isResetForm = false,
  isExamOn,
  isDebugOn,
  dispatch,
}) => {
  //=================================================================
  const { nodes, rows } = projectCurrent
  const { isStatusLocked } = projectCurrent.setup

  //-----------------------------------------------------------------
  const [sheetHeight, setSheetHeight] = useState(12000)
  const ref = useRef(null)

  useLayoutEffect(() => {
    setSheetHeight(ref.current.clientHeight)
  }, [sheetHeight, projectCurrent])

  //=================================================================
  return (
    //
    <SimpleBar
      style={{
        minHeight: 300,
        maxHeight: sheetHeight,
      }}
    >
      <Box>
        <Box mb={16} mr={2} ref={ref}>
          {rows.map((nId, index) => {
            const node = { ...nodes[nId], ...nodesInfos[nId] }

            return (
              <SheetRow
                key={nId}
                row={index}
                node={node}
                isTop={nodeIsTopCalc(nodes, nId)}
                isBottom={nodeIsBottomCalc(nodes, nId)}
                isStatusLocked={isStatusLocked}
                dateStatusCurrent={dateStatusCurrent}
                params={params}
                dispatch={dispatch}
                isResetForm={isResetForm}
                isExamOn={isExamOn}
                isDebugOn={isDebugOn}
              />
            )
          })}
        </Box>
        <Box h={sheetHeight}></Box>
      </Box>
    </SimpleBar>
  )
}

const mapStateToProps = (state) => {
  return {
    projectCurrent: state.project.dates[state.project.dateStatusCurrent],
    dateStatusCurrent: state.project.dateStatusCurrent,
    nodesInfos: getNodesInfos(state),
    params: state.project.params,
    isResetForm: state.project.isResetForm,
    isExamOn: state.app.isExamOn,
    isDebugOn: state.app.isDebugOn,
  }
}

export default connect(mapStateToProps)(SheetRows)
