import produce from "immer"

import {
  messageFromAppDelete,
  isFactoryResetUpdate,
  isExamOnUpdate,
  isDebugOnUpdate,
} from "../actions/app"

const settings = (slice = [], action) => {
  let sliceNext = slice

  if (sliceNext !== undefined && sliceNext.isFactoryReset !== undefined) {
    sliceNext = produce(sliceNext, (sliceDraft) => {
      sliceDraft.isFactoryReset = false
    })
  }

  const { payload } = action

  switch (action.type) {
    // MESSAGE -------------------------------------------------------
    case messageFromAppDelete.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.messageFromApp = ""
      })
      return sliceNext

    case isExamOnUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.isExamOn = !sliceDraft.isExamOn
      })
      return sliceNext

    case isDebugOnUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.isDebugOn = !sliceDraft.isDebugOn
      })
      return sliceNext

    case isFactoryResetUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.isFactoryReset = payload.isFactoryReset
      })
      return sliceNext

    default:
      return sliceNext
  }
}

export default settings
