import COLORS from "../../../theme/colors"
import ClearIndicatorStyles from "../../SelectPerson/style/ClearIndicatorStyles"

// https://react-select.com/styles
const customStyles = {
  control: (provided) => ({ display: "flex" }),
  clearIndicator: ClearIndicatorStyles,
  indicatorsContainer: (provided, state) => ({
    position: "relative",
    left: "4px",
    color: "#bdbdbd", //gre.400
    background: "transparent",
    "&:hover": { color: COLORS.red[500] },
  }),
  container: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderColor: COLORS.grey[400],
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: "0",
    textOverflow: "clip",
    color: state.selectProps.isIgnored
      ? COLORS.grey[400]
      : state.selectProps.isLocked
      ? "inherit"
      : "inherit",
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: "0",
  }),
  menu: (provided) => ({
    ...provided,
    width: 120,
    borderRadius: 0,
  }),
  option: (provided) => ({
    ...provided,
    margin: "0",
    padding: "0 4px",
  }),
  input: (provided) => ({}),
}

export default customStyles
