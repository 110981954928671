import project from "./projectInitialState"
import settings from "./settingsInitialState"
import app from "./appInitialState"

const initialState = {
  ...project,
  ...settings,
  ...app,
}

export default initialState
