import React from "react"
import { chakra, Box } from "@chakra-ui/react"

const SwitchRound = ({
  color = "red.500",
  borderColor = "",
  isOn,
  isDisabled,
  onClick,
}) => {
  return (
    <chakra.button
      type="button"
      onClick={isDisabled ? null : onClick}
      position="relative"
      top="4px"
      h="16px"
      w="16px"
      border={
        borderColor !== "" ? `1px solid ${borderColor}` : "1px solid gray"
      }
      borderRadius="50%"
      display="inline-block"
      cursor={onClick === undefined || isDisabled ? "default" : "pointer"}
      textAlign="left"
      outline="none"
    >
      <Box
        h="10px"
        w="10px"
        position="relative"
        top="-5px"
        left="2px"
        bg={isOn ? color : "white"}
        borderRadius="50%"
        display="inline-block"
      ></Box>
    </chakra.button>
  )
}

export default SwitchRound
