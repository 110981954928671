import { ROOT } from "../../const/globals.js"
import { copyDeep } from "../utils/objectCopyDeep.js"
import nodeCreate from "./nodeCreate"
import { byWhenFctSet } from "../../functions/timeHandler/timeHandler"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Adds a nodeNew after an existing node
 * @param {object} nodes - nodes tree
 * @param {string} nIdBefore - Id of the node before
 * @return {object} nodes - new nodes tree
 */
const nodeNewAddAfter = (nodes, nIdBefore) => {
  let nodesNew = copyDeep(nodes)
  let nodeNew = nodeCreate({ nId: null })
  const pId = nodes[nIdBefore].pId
  const nId = nodeNew.nId
  nodesNew[nId] = nodeNew

  if (nIdBefore !== ROOT) {
    // take over pId
    nodesNew[nId].pId = pId
    // enter nId into pId.children array after nIdBefore
    const index = nodesNew[pId].children.indexOf(nIdBefore)
    nodesNew[pId].children.splice(index + 1, 0, nId)
    nodesNew[nId].fromWhen = nodesNew[pId].fromWhen
    nodesNew[nId].byWhen = nodesNew[pId].byWhen
  } else {
    nodesNew[nId].pId = ROOT
    // take over level
    nodesNew[ROOT].children.splice(0, 0, nId)
    nodesNew[nId].position.length = 1
    nodesNew[nId].fromWhen = nodesNew[ROOT].fromWhen
    nodesNew[nId].byWhen = nodesNew[ROOT].byWhen
  }

  nodesNew = byWhenFctSet(nodesNew, nId)

  return nodesNew
}

export default nodeNewAddAfter
