import React from "react"
import { connect } from "react-redux"
import { Box } from "@chakra-ui/react"
import timeLineGen from "../../functions/timeHandler/timeLineGen"
import SimpleBar from "simplebar-react"
import getNodesInfos from "../../redux/reselect/getNodesInfos"
import { ROOT } from "../../const/globals"
import GanttTimeLine from "../GanttTimeLine"
import GanttBars from "../GanttBars"
import max from "date-fns/max"
import parseISO from "date-fns/parseISO"

const GanttChart = ({
  projectCurrent,
  nodesInfos,
  settings,
  isResetForm = false,
  datesStatusList,
  dateStatusCurrent,
  dispatch,
}) => {
  //=================================================================

  const { ganttDurationIndex, boxW } = settings
  const isViewWeek = ganttDurationIndex === 1

  const dateEndDate = max([
    parseISO(projectCurrent.nodes[ROOT].byWhen),
    parseISO(datesStatusList[0]),
  ])

  const dateStartISO = projectCurrent.nodes[ROOT].fromWhen
  const dateEndISO = dateEndDate.toISOString()

  //-----------------------------------------------------------------
  const { timeLine, weekStartISO } = timeLineGen(
    dateStartISO,
    dateEndISO,
    isViewWeek
  )

  //=================================================================
  return (
    <>
      <SimpleBar>
        <Box mb={4}>
          <GanttTimeLine
            timeLine={timeLine}
            datesStatusList={datesStatusList}
            dateStatusCurrent={dateStatusCurrent}
            dateStart={isViewWeek ? weekStartISO : dateStartISO}
            dateEnd={dateEndISO}
            ganttDurationIndex={ganttDurationIndex}
            boxW={boxW}
          />
          <GanttBars
            timeLine={timeLine}
            projectCurrent={projectCurrent}
            nodesInfos={nodesInfos}
            ganttDurationIndex={ganttDurationIndex}
            boxW={boxW}
            dispatch={dispatch}
          />
        </Box>
      </SimpleBar>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    projectCurrent: state.project.dates[state.project.dateStatusCurrent],
    datesStatusList: state.project.datesStatusList,
    dateStatusCurrent: state.project.dateStatusCurrent,
    nodesInfos: getNodesInfos(state),
    settings: state.settings,
  }
}

export default connect(mapStateToProps)(GanttChart)
